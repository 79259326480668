import { LanguagesEnum } from '@/api/enums';
import { handleAxiosError } from '@/api/helpers';
import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import { client } from '@/client';
import { CourseFilesType, CourseStatusAttemptEnum } from '@/client/courses';
import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import { TranslationFunctionType } from '@/common/types';
import { CourseLanguageChanger } from '@/components/courses/CourseLanguageChanger';
import { CourseLanguageSelectScreen } from '@/components/courses/CourseLanguageSelectScreen';
import { useCourse, useCourseAttempt } from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { useToast } from '@/hooks/useToast';
import { selectCurrentUser } from '@/store/features/users';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import { AppButton } from '@/ui/buttons';
import { AppChip } from '@/ui/chip';
import { PDFViewer } from '@/ui/pdf-viewer';
import { FlexContainer } from '@/ui/styled-ui';
import {
  getTranslatedCourseStatusAttempt,
  getTranslationByLanguage,
  isUserLanguageAvailableInCourse,
} from '@/utils/helpers';
import { isMobile } from '@/utils/helpers/ui.helper';
import { AxiosError } from 'axios';
import { MenuItem } from 'primereact/menuitem';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

const StyledFlexContainer = styled(FlexContainer)`
  word-break: break-word;

  &.stretch {
    height: 100%;
  }

  @media screen and (max-width: 424px) {
    &.stretch {
      height: auto;
    }
  }
`;

const StyledAppChip = styled(AppChip)`
  margin-left: var(--small-padding);

  &.mobile .p-chip-text {
    margin: var(--xxsmall-padding);
  }
`;

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: var(--small-padding);

  h1 {
    margin-bottom: 0;

    @media screen and (max-width: 767px) {
      font-size: var(--small-font-size);
      line-height: var(--small-line-height);
    }
  }
`;

const getAppChipType = (status: string | undefined) => {
  switch (status) {
    case CourseStatusAttemptEnum.IN_PROGRESS:
      return 'secondary';
    case CourseStatusAttemptEnum.COMPLETED:
      return 'primary';
    default:
      return 'secondary';
  }
};

export const DocumentViewPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();
  const { id: courseId, attemptId } = useParams();
  const currentUser = useAppSelector(selectCurrentUser);
  const [status, setStatus] = useState<CourseStatusAttemptEnum>();
  const [isCourseStarted, setIsCourseStarted] = useState<boolean>(false);
  const [isUrlLoading, setIsUrlLoading] = useState<boolean>(false);
  const [fileDetails, setFileDetails] = useState<CourseFilesType>();

  // Fetch course data
  const { course, isLoading: isCourseLoading } = useCourse({
    courseId,
    staleTime: Infinity,
  });

  const { courseAttempt, isLoading: isAttemptLoading } = useCourseAttempt({
    courseId,
    attemptId,
  });

  const [selectedLanguage, setSelectedLanguage] = useState<
    LanguagesEnum | undefined
  >(undefined);

  const [attemptLanguage, setAttemptLanguage] = useState<
    LanguagesEnum | undefined
  >(undefined);

  const updateCourseAttempt = async (page?: number, isComplete = false) => {
    if (attemptId && course && courseAttempt) {
      try {
        const payload = isComplete
          ? { status: CourseStatusAttemptEnum.COMPLETED }
          : { payload: { ...courseAttempt.payload, page } };

        await client.learnerCourses.updateCourseAttempt({
          courseId: course.id,
          attemptId: attemptId,
          language: attemptLanguage,
          payload,
        });

        if (isComplete) {
          toast?.success(t('toast.success'), t('material.complete.success'));
        }
      } catch (e) {
        handleAxiosError(e as AxiosError, toast);
      }
    }
  };

  useEffect(() => {
    if (
      course?.courseLanguages?.length === 1 ||
      !currentUser ||
      !course ||
      isAttemptLoading
    ) {
      return;
    }

    // Set initial preselected language if not available set to English
    setSelectedLanguage(
      courseAttempt?.language
        ? courseAttempt.language
        : currentUser?.language &&
          course &&
          isUserLanguageAvailableInCourse(
            course?.courseFiles,
            currentUser?.language as LanguagesEnum,
          )
        ? (currentUser?.language as LanguagesEnum)
        : LanguagesEnum.EN,
    );
  }, [currentUser, course, courseAttempt]);

  const courseName = getTranslationByLanguage(
    course?.courseTranslations ?? [],
    currentUser?.language
      ? (currentUser?.language as LanguagesEnum)
      : LanguagesEnum.EN,
  )?.name;

  useEffect(() => {
    setStatus(courseAttempt?.status);
  }, [courseAttempt]);

  useEffect(() => {
    if (course?.courseLanguages?.length === 1) {
      setIsCourseStarted(true);
    }

    if (courseAttempt?.language) {
      setIsCourseStarted(true);
    }

    fetchMaterialFile();
  }, [course, courseAttempt]);

  useEffect(() => {
    fetchMaterialFile();
  }, [selectedLanguage]);

  const getLanguage = () => {
    if (course?.courseLanguages?.length === 1) {
      return course?.courseLanguages[0];
    }

    return selectedLanguage;
  };

  const fetchMaterialFile = async () => {
    const courseLanguage = getLanguage();

    if (course && courseLanguage) {
      try {
        setFileDetails(undefined);
        setIsUrlLoading(true);
        const courseFile = await client.files.getCourseFile(
          course.id,
          courseLanguage,
        );
        setFileDetails(courseFile);
        setAttemptLanguage(courseLanguage);
      } catch (e) {
        handleAxiosError(e as Error | AxiosError, toast);
      } finally {
        setIsUrlLoading(false);
      }
    }
  };

  const getBreadcrumbs = (t: TranslationFunctionType): MenuItem[] => [
    {
      label: t('materials'),
      url:
        location?.state?.from ||
        RedirectPaths[RedirectPathsEnum.LEARNER_DASHBOARD_MATERIALS_TODO](),
      template: AppBreadCrumbTemplate,
    },
    {
      label: courseName,
      disabled: true,
      template: AppBreadCrumbTemplate,
    },
  ];

  const navigateToMaterials = async () => {
    if (course) {
      navigate(
        RedirectPaths[RedirectPathsEnum.LEARNER_DASHBOARD_MATERIALS_TODO](),
        {
          state: location?.state,
        },
      );
    }
  };

  return (
    <>
      {(!currentUser || isAttemptLoading || isCourseLoading) && (
        <FlexContainer direction="column" className="h-full">
          <FlexContainer direction="column" className="mt-5 flex-1">
            <ProgressSpinner />
            <h3>{t('course.loading')}</h3>
          </FlexContainer>
        </FlexContainer>
      )}
      {!isAttemptLoading && !isCourseLoading && currentUser && course && (
        <StyledFlexContainer
          direction="column"
          align="flex-start"
          className={!isCourseStarted && course.selfHosted ? 'stretch' : ''}
          gap={24}
        >
          <AppBreadCrumb model={getBreadcrumbs(t)} className="no-space" />
          <FlexContainer justify="space-between" wrap="wrap">
            <StyledDiv>
              <AppButton
                type="outlined"
                icon="pi pi-arrow-left"
                size={isMobile() ? 'xs' : 'sm'}
                ariaLabel={t('course.player.back')}
                onClick={
                  isMobile()
                    ? () =>
                        navigate(
                          location?.state?.from ||
                            RedirectPaths[
                              RedirectPathsEnum.LEARNER_DASHBOARD_MATERIALS_TODO
                            ](),
                        )
                    : navigateToMaterials
                }
              />
              <h1>{courseName}</h1>
              <StyledAppChip
                className={isMobile() ? 'mobile' : ''}
                key={status}
                label={getTranslatedCourseStatusAttempt(status)}
                type={getAppChipType(status)}
              />
            </StyledDiv>
            <StyledDiv>
              {isCourseStarted &&
                course?.courseLanguages?.length > 1 &&
                !isMobile() && (
                  <CourseLanguageChanger
                    language={selectedLanguage}
                    course={course}
                    shouldShowWarning={false}
                    onChange={(value) => setSelectedLanguage(value)}
                  />
                )}

              {isCourseStarted && !isMobile() && (
                <AppButton
                  type="outlined"
                  icon="pi pi-times"
                  size={isMobile() ? 'xs' : 'sm'}
                  ariaLabel={t('courses.back')}
                  onClick={() =>
                    navigate(
                      location?.state?.from ||
                        RedirectPaths[
                          RedirectPathsEnum.LEARNER_DASHBOARD_MATERIALS_TODO
                        ](),
                    )
                  }
                />
              )}
            </StyledDiv>
          </FlexContainer>

          {isCourseStarted && !isUrlLoading && fileDetails?.file?.signedUrl && (
            <div className="mt-3 w-full">
              <PDFViewer
                fileUrl={fileDetails.file.signedUrl}
                documentWidth={
                  document?.querySelector('.without-sidebar')?.clientWidth
                }
                status={courseAttempt?.status}
                currentPage={courseAttempt?.payload?.page}
                onPageChange={(page) => updateCourseAttempt(page)}
                onComplete={() => updateCourseAttempt(undefined, true)}
              />
            </div>
          )}

          {!isCourseStarted && !courseAttempt?.language && (
            <CourseLanguageSelectScreen
              course={course}
              language={selectedLanguage}
              isLoading={isUrlLoading}
              isDisabled={!fileDetails}
              isMaterial
              setIsCourseStarted={setIsCourseStarted}
              setSelectedLanguage={setSelectedLanguage}
            />
          )}

          {isCourseStarted &&
            course?.courseLanguages?.length > 1 &&
            isMobile() && (
              <FlexContainer
                justify="space-between"
                className="w-full"
                gap={16}
              >
                <CourseLanguageChanger
                  className="player"
                  language={selectedLanguage}
                  course={course}
                  shouldShowWarning={false}
                  onChange={(value) => setSelectedLanguage(value)}
                />
              </FlexContainer>
            )}
        </StyledFlexContainer>
      )}
    </>
  );
};
