import { Account } from '@/client/accounts';
import { Actions, Subjects } from '@/client/users';
import {
  DashboardType,
  LAST_SELECTED_DASHBOARD,
  RedirectPaths,
  RedirectPathsEnum,
} from '@/common/constants';
import { TranslationFunctionType } from '@/common/types';
import { SidebarAccountName } from '@/components/navigation/SidebarAccountName';
import { SidebarAccountSelect } from '@/components/navigation/SidebarAccountSelect';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { usePermission } from '@/hooks/usePermission';
import {
  selectCurrentAccount,
  setCurrentAccount,
} from '@/store/features/account';
import { selectCurrentUser } from '@/store/features/users';
import { AppChip } from '@/ui/chip';
import {
  accountHasDocuments,
  AwarenessReportsIconPath,
  branchAdminCheck,
  CampaignsIconPath,
  CoursesIconPath,
  EmailTemplatesIconPath,
  FindUserIconPath,
  isOutsideOfEfront,
  isPhishingEnabled,
  isUserManagementEnabled,
  PageTemplatesIconPath,
  PoliciesAndDocsIconPath,
  // PostersIconPath,
  RedirectTemplatesIconPath,
  ResellersIconPath,
  ScheduleIconPath,
  SenderIconPath,
  SummaryReportsIconPath,
  SyncIconPath,
} from '@/utils/helpers';
import classNames from 'classnames';
import { MenuItem } from 'primereact/menuitem';
import { PanelMenu as PrimePanelMenu } from 'primereact/panelmenu';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const StyledAppChip = styled(AppChip)`
  margin-inline: var(--medium-padding);
  padding-inline: var(--default-padding);
  width: calc(100% - 2 * var(--medium-padding));
  background-color: var(--red-dark) !important;
  color: var(--white-main) !important;
`;

const StyledPanelMenu = styled(PrimePanelMenu)`
  min-width: min-content;
  &.p-panelmenu {
    width: 100%;
    border: none;

    .p-panelmenu-panel {
      margin: 0 var(--medium-padding);

      .p-toggleable-content {
        border-bottom: 1px solid var(--purple-highlight);
        margin: 0 var(--default-padding);
      }

      .p-panelmenu-header {
        .p-panelmenu-header-link {
          margin: 0 var(--default-padding);
          border-bottom: 1px solid var(--purple-highlight);
        }

        &:not(.p-highlight) .p-panelmenu-header-content {
          :not(.p-disabled) > a:not(.p-highlight):hover {
            background: var(--purple-dark);
            color: var(--purple-light);
            border-bottom: 1px solid var(--purple-highlight);
          }
        }

        &.p-highlight {
          .p-panelmenu-header-link {
            border-bottom: 1px solid transparent;
          }
          > a {
            border-radius: 0;
            border: none;
            background: var(--purple-dark);
            color: var(--white-main);
            border-color: var(--purple-highlight);
            box-shadow: none;
          }

          &:not(.p-disabled):not(.link-header) > a:hover {
            background: var(--purple-dark);
            color: var(--white-main);
            border-radius: 0;
            border-bottom: 1px solid transparent;
          }
        }
      }

      .p-panelmenu-header-link {
        padding: var(--medium-padding) 0;
        font-size: var(--xsmall-font-size);
        line-height: var(--xsmall-line-height);
        text-transform: uppercase;
        font-weight: 500;
        justify-content: space-between;
        border: 0;
        border-radius: 0;
        background: transparent;
        color: var(--white-main);

        &:focus,
        &.p-highlight {
          box-shadow: none;
        }

        &.link-header {
          border: 1px solid transparent;
          border-bottom: 1px solid var(--purple-highlight);
          padding-bottom: calc(var(--medium-padding) + 1px);
          &.p-highlight {
            top: -1px;
            overflow: hidden;
            border: 1px solid var(--purple-highlight);
            border-radius: var(--default-border-radius);
            padding: var(--medium-padding) var(--default-padding);
            padding-top: calc(var(--medium-padding) + 1px);
            margin: 0;
            &:not(.p-disabled):hover {
              color: var(--white-main);
              border: 1px solid var(--purple-highlight);
            }
          }
        }
      }

      .p-panelmenu-content {
        font-size: var(--small-font-size);
        line-height: var(--small-line-height);
        padding: 0;
        margin-bottom: var(--xsmall-padding);
        border: 0;
        border-radius: 0;
        background: var(--purple-dark);
        color: var(--white-main);

        .p-menuitem {
          margin-bottom: var(--xsmall-padding);
          .p-menuitem-icon {
            font-size: var(--small-font-size);
            margin-right: var(--small-padding);
            color: var(--white-main);
          }

          .p-menuitem-text {
            font-size: var(--small-font-size);
            line-height: var(--small-line-height);
          }

          .p-menuitem-link {
            background: var(--purple-dark);
            padding: var(--small-padding) var(--medium-padding);
            border: 1px solid transparent;

            .custom-icon {
              flex-basis: 24px;
              min-width: 24px;
            }

            &:focus {
              box-shadow: var(--box-shadow-red-inset);
              border: 1px solid var(--purple-highlight);
              border-radius: var(--default-border-radius);
            }
            &.p-menuitem-link-active {
              border: 1px solid var(--purple-highlight);
              border-radius: var(--default-border-radius);
              overflow: hidden;
            }

            .p-menuitem-text {
              color: var(--white-main);
            }

            &:not(.p-disabled):hover {
              background: var(--purple-dark);
              color: var(--purple-light);

              .custom-icon {
                fill: var(--purple-light);
              }

              .p-menuitem-icon,
              .p-menuitem-text {
                color: var(--purple-light);
              }

              &.p-menuitem-link-active {
                .p-menuitem-icon,
                .p-menuitem-text {
                  color: var(--white-main);
                }
                .custom-icon {
                  fill: var(--white-main);
                }
              }
            }
            .custom-icon {
              fill: var(--white-main);
            }
          }
        }
      }

      .p-submenu-icon {
        order: 1;
        font-size: var(--medium-font-size);
        margin-right: 0;
        margin-left: var(--small-padding);
      }

      &.section-title {
        opacity: 100%;
        .p-panelmenu-header-link {
          text-transform: capitalize;
          margin-top: var(--small-padding);
          padding-top: var(--default-padding);
          padding-bottom: var(--small-padding);
          color: var(--purple-light);
          border-bottom: none;
        }
      }
    }
    .p-panelmenu-panel:last-child {
      .p-panelmenu-header {
        .p-panelmenu-header-link:not(.p-highlight) {
          border-bottom: 1px solid transparent;
        }
        &:not(.p-highlight) .p-panelmenu-header-content {
          :not(.p-disabled) > a:hover {
            border-color: transparent;
            &.p-highlight {
              &.link-header {
                border-color: var(--purple-highlight);
              }
            }
          }
        }
      }
      .p-toggleable-content:last-child {
        border-bottom: 1px solid transparent;
      }
    }
  }
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  min-width: 240px;
`;

const StyledFooter = styled.footer`
  font-size: var(--xsmall-font-size);
  line-height: var(--small-line-height);
  color: var(--white-main);
  font-weight: 500;
  padding: var(--default-padding) var(--default-padding) 0;
  margin: 0 var(--medium-padding);

  span,
  a {
    color: var(--purple-light);
  }

  a {
    text-decoration: none;
  }
`;

const getItemTemplate = (url: string, current: string) => (item: MenuItem) => {
  const getUrl = /[^/]*.[^/]*/.exec(url);
  const regex = new RegExp(`^${getUrl?.length ? getUrl[0] : url}$`);

  const currentLocationStart = `/${current.split('/')[1]}`;

  return (
    <Link
      to={url}
      className={classNames({
        'p-menuitem-link': true,
        'p-menuitem-link-active': currentLocationStart.match(regex),
      })}
      role="menuitem"
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        className="p-menuitem-icon custom-icon"
      >
        {item.icon}
      </svg>
      <span className="p-menuitem-text">{item.label}</span>
    </Link>
  );
};

const getItemTemplateHeader =
  (url: string, current: string, matchStart?: boolean) => (item: MenuItem) => {
    const getUrl = /[^/]*.[^/]*/.exec(url);
    const regex = new RegExp(`^${getUrl?.length ? getUrl[0] : url}$`);

    const currentLocationStart = `/${current.split('/')[1]}`;

    return (
      <Link
        to={url}
        className={classNames({
          'p-panelmenu-header-link': true,
          'p-highlight': matchStart
            ? currentLocationStart.match(regex)
            : current.includes(url),
          'link-header': !item.items,
        })}
        role="menuitem"
      >
        <span className="p-menuitem-text">{item.label}</span>
      </Link>
    );
  };

const getMenu = (
  can: (action: Actions, subject: Subjects) => boolean,
  cannot: (action: Actions, subject: Subjects) => boolean,
  accountsCount: number,
  onClick: (route: string) => void,
  current: string,
  t: TranslationFunctionType,
  account: Account | null,
  isBranchAdmin: boolean,
): MenuItem[] => {
  const isSystem = account?.isSystem;
  const menu: MenuItem[] = [];
  const coursesSubMenu: MenuItem[] = [];
  const campaignSubMenu: MenuItem[] = [];
  const reportsSubMenu: MenuItem[] = [];
  // const postersSubMenu: MenuItem[] = [];
  const tools: MenuItem[] = [];
  const hasDocuments = accountHasDocuments(account);

  // ------------------ HOME ------------------ //
  if (isOutsideOfEfront() && can(Actions.READ, Subjects.ADMIN_DASHBOARD)) {
    menu.push({
      label: t('sidebar.home'),
      className: 'section-title p-disabled',
    });

    menu.push({
      label: t('generic.dashboard'),
      template: getItemTemplateHeader(
        localStorage.getItem(LAST_SELECTED_DASHBOARD) === DashboardType.LEARNER
          ? RedirectPaths[RedirectPathsEnum.LEARNER_DASHBOARD_ACTIVE]()
          : RedirectPaths[RedirectPathsEnum.ADMIN_DASHBOARD](),
        current,
      ),
    });
  }

  // ------------------ TRAININGS ------------------ //
  if (isOutsideOfEfront() || isPhishingEnabled()) {
    menu.push({
      label: t('sidebar.trainings'),
      className: 'section-title p-disabled',
    });
  }

  // ------------------ TRAININGS-AWARENESS ------------------ //

  if (can(Actions.READ, Subjects.COURSES)) {
    coursesSubMenu.push({
      label: t('courses'),
      icon: <CoursesIconPath />,
      template: getItemTemplate(
        RedirectPaths[RedirectPathsEnum.COURSES](),
        current,
      ),
    });
  }

  if (
    can(Actions.READ, Subjects.COURSE_PLANNER) &&
    !isSystem &&
    account?.meta?.enableCoursePlanner
  ) {
    coursesSubMenu.push({
      label: t('courses.trainingPlanner'),
      icon: <ScheduleIconPath />,
      template: getItemTemplate(
        RedirectPaths[RedirectPathsEnum.ACCOUNT_COURSE_PLANNER](),
        current,
      ),
    });
  }

  if (
    can(Actions.READ, Subjects.COURSE_ACCOUNT_SCHEDULES) &&
    !isSystem &&
    !account?.meta?.enableCoursePlanner
  ) {
    coursesSubMenu.push({
      label: t('schedule'),
      icon: <ScheduleIconPath />,
      template: getItemTemplate(
        RedirectPaths[RedirectPathsEnum.ACCOUNT_COURSE_SCHEDULE](),
        current,
      ),
    });
  }

  if (isOutsideOfEfront() && coursesSubMenu.length > 0) {
    menu.push({
      label: t('sidebar.awareness'),
      items: coursesSubMenu,
      expanded: !!current.match(/^\/(courses|enrollment)/),
    });
  }

  // ------------------ TRAININGS-PHISHING ------------------ //
  if (can(Actions.READ, Subjects.CAMPAIGNS)) {
    campaignSubMenu.push({
      label: t('campaigns'),
      icon: <CampaignsIconPath />,
      template: getItemTemplate(
        RedirectPaths[RedirectPathsEnum.CAMPAIGNS](),
        current,
      ),
    });
  }

  if (can(Actions.READ, Subjects.EMAIL_TEMPLATES)) {
    campaignSubMenu.push({
      label: t('templates.emailTemplates'),
      icon: <EmailTemplatesIconPath />,
      template: getItemTemplate(
        RedirectPaths[RedirectPathsEnum.EMAIL_TEMPLATES_PREDEFINED](),
        current,
      ),
    });
  }

  if (can(Actions.READ, Subjects.PAGES)) {
    campaignSubMenu.push({
      label: t('templates.pageTemplates'),
      icon: <PageTemplatesIconPath />,
      template: getItemTemplate(
        RedirectPaths[RedirectPathsEnum.LANDING_PAGE_TEMPLATES_PREDEFINED](),
        current,
      ),
    });
  }

  if (can(Actions.READ, Subjects.PAGES)) {
    campaignSubMenu.push({
      label: t('templates.redirectTemplates'),
      icon: <RedirectTemplatesIconPath />,
      template: getItemTemplate(
        RedirectPaths[RedirectPathsEnum.REDIRECT_PAGE_TEMPLATES_PREDEFINED](),
        current,
      ),
    });
  }

  if (can(Actions.READ, Subjects.SENDING_PROFILES)) {
    campaignSubMenu.push({
      label: t('sender'),
      icon: <SenderIconPath />,
      template: getItemTemplate(
        RedirectPaths[RedirectPathsEnum.SENDER_PROFILES_PREDEFINED](),
        current,
      ),
    });
  }

  if (isPhishingEnabled() && campaignSubMenu.length > 0) {
    menu.push({
      label: t('campaign.phishing'),
      items: campaignSubMenu,
      expanded: !!current.match(
        /^\/(landing-page-templates|redirect-page-templates|email-templates|sender-profiles|campaigns)/,
      ),
    });
  }

  // ------------------ TRAININGS-PHISHING ------------------ //
  if (can(Actions.READ, Subjects.ACCOUNT_REPORTS) && !isSystem) {
    reportsSubMenu.push(
      {
        label: t('reports.summary'),
        icon: <SummaryReportsIconPath />,
        template: getItemTemplate(
          RedirectPaths[RedirectPathsEnum.REPORTS_SUMMARY](),
          current,
        ),
      },
      {
        label: t('reports.awareness'),
        icon: <AwarenessReportsIconPath />,
        template: getItemTemplate(
          RedirectPaths[RedirectPathsEnum.REPORTS_AWARENESS](),
          current,
        ),
      },
    );
  }

  if (isOutsideOfEfront() && reportsSubMenu.length > 0) {
    menu.push({
      label: t('sidebar.reports'),
      items: reportsSubMenu,
      expanded: !!current.match(/^\/(reports-summary|reports-awareness)/),
    });
  }

  // ------------------ MATERIALS ------------------ //
  if (
    isOutsideOfEfront() &&
    (account?.meta?.enableMaterialsUpload || hasDocuments || account?.isSystem)
  ) {
    menu.push({
      label: t('sidebar.materials'),
      className: 'section-title p-disabled',
    });
  }

  if (
    isOutsideOfEfront() &&
    can(Actions.READ, Subjects.COURSES) &&
    (hasDocuments || account?.isSystem)
  ) {
    menu.push({
      label: t('resources'),
      template: getItemTemplateHeader(
        RedirectPaths[RedirectPathsEnum.RESOURCES](),
        current,
      ),
    });
  }

  if (
    isOutsideOfEfront() &&
    can(Actions.READ, Subjects.COURSES) &&
    account?.meta?.enableMaterialsUpload
  ) {
    menu.push({
      label: t('custom.materials'),
      template: getItemTemplateHeader(
        RedirectPaths[RedirectPathsEnum.CUSTOM_MATERIALS](),
        current,
      ),
    });
  }

  /*
  // ------------------ MATERIALS-POSTERS ------------------ //
  if (isOutsideOfEfront() && can(Actions.READ, Subjects.COURSES)) {
    menu.push({
      label: t('posters'),
      template: getItemTemplateHeader(
        RedirectPaths[RedirectPathsEnum.POSTERS](),
        current,
      ),
    });
  }
  */

  // ------------------ SETTINGS ------------------ //
  if (
    isOutsideOfEfront() ||
    isUserManagementEnabled() ||
    can(Actions.READ, Subjects.ACCOUNTS) ||
    (can(Actions.READ, Subjects.EFRONT_SYNC) && isSystem) ||
    (can(Actions.READ, Subjects.SYSTEM_SETTINGS) &&
      isOutsideOfEfront() &&
      isPhishingEnabled())
  ) {
    menu.push({
      label: t('sidebar.settings'),
      className: 'section-title p-disabled',
    });
  }

  if (
    isUserManagementEnabled() &&
    can(Actions.READ, Subjects.ACCOUNTS) &&
    cannot(Actions.READ, Subjects.OWN_ACCOUNT)
  ) {
    menu.push({
      label: isSystem ? t('accounts') : t('account'),
      template: getItemTemplateHeader(
        isSystem
          ? RedirectPaths[RedirectPathsEnum.ACCOUNTS]()
          : account
          ? RedirectPaths[RedirectPathsEnum.ACCOUNT](account?.id)
          : RedirectPaths[RedirectPathsEnum.CREATE_ACCOUNT](),
        current,
        true,
      ),
    });
  }

  if (
    isUserManagementEnabled() &&
    can(Actions.READ, Subjects.OWN_ACCOUNT) &&
    account
  ) {
    menu.push({
      label: t('account'),
      template: getItemTemplateHeader(
        RedirectPaths[RedirectPathsEnum.ACCOUNT](account?.id),
        current,
        true,
      ),
    });
  }

  if (isUserManagementEnabled() && can(Actions.READ, Subjects.USERS)) {
    menu.push({
      label: t('users'),
      template: getItemTemplateHeader(
        RedirectPaths[RedirectPathsEnum.USERS](),
        current,
        true,
      ),
    });
  }

  if (isUserManagementEnabled() && can(Actions.READ, Subjects.BRANCHES)) {
    menu.push({
      label: t('branches'),
      template: getItemTemplateHeader(
        RedirectPaths[RedirectPathsEnum.BRANCHES](),
        current,
        true,
      ),
    });
  }

  if (
    isUserManagementEnabled() &&
    !isBranchAdmin &&
    can(Actions.READ, Subjects.GROUPS)
  ) {
    menu.push({
      label: t('groups'),
      template: getItemTemplateHeader(
        RedirectPaths[RedirectPathsEnum.GROUPS](),
        current,
        true,
      ),
    });
  }

  if (
    isOutsideOfEfront() &&
    isPhishingEnabled() &&
    can(Actions.READ, Subjects.SYSTEM_SETTINGS)
  ) {
    menu.push({
      label: t('sidebar.emails'),
      template: getItemTemplateHeader(
        RedirectPaths[RedirectPathsEnum.SYSTEM_EMAILS_TEMPLATES](),
        current,
        true,
      ),
    });
  }

  if (
    isOutsideOfEfront() &&
    isPhishingEnabled() &&
    can(Actions.READ, Subjects.SECURITY_CULTURE) &&
    !isSystem
  ) {
    menu.push({
      label: t('security.culture'),
      template: getItemTemplateHeader(
        RedirectPaths[RedirectPathsEnum.SECURITY_CULTURE](),
        current,
      ),
    });
  }

  // ------------------ SETTINGS-TOOLS ------------------ //
  if (can(Actions.READ, Subjects.EFRONT_SYNC) && isSystem) {
    if (isOutsideOfEfront() || isUserManagementEnabled()) {
      tools.push({
        label: t('sidebar.efront'),
        icon: <SyncIconPath />,
        template: getItemTemplate(
          RedirectPaths[RedirectPathsEnum.EFRONT_SYNC](),
          current,
        ),
      });
    }
    if (isOutsideOfEfront() || isPhishingEnabled()) {
      tools.push({
        label: t('sidebar.campaigns'),
        icon: <SyncIconPath />,
        template: getItemTemplate(
          RedirectPaths[RedirectPathsEnum.OLD_CAMPAIGNS_WIZARD](),
          current,
        ),
      });
    }
  }

  if (can(Actions.READ, Subjects.HUBSPOT_ACCOUNT_LINK) && isSystem) {
    if (isOutsideOfEfront() || isPhishingEnabled()) {
      tools.push({
        label: t('hubspot.forceSync'),
        icon: <SyncIconPath />,
        template: getItemTemplate(
          RedirectPaths[RedirectPathsEnum.HUBSPOT_SYNC](),
          current,
        ),
      });
    }
  }

  if (can(Actions.READ, Subjects.RESELLERS) && isSystem) {
    if (isOutsideOfEfront()) {
      tools.push({
        label: t('sidebar.resellers'),
        icon: <ResellersIconPath />,
        template: getItemTemplate(
          RedirectPaths[RedirectPathsEnum.RESELLERS](),
          current,
        ),
      });
    }
  }

  if (can(Actions.READ, Subjects.USERS) && isSystem) {
    if (isOutsideOfEfront()) {
      tools.push({
        label: t('user.find'),
        icon: <FindUserIconPath />,
        template: getItemTemplate(
          RedirectPaths[RedirectPathsEnum.FIND_USER](),
          current,
        ),
      });
    }
  }

  if (tools.length > 0) {
    menu.push({
      label: t('sidebar.tools'),
      items: tools,
      expanded: !!current.match(
        /^\/(efront-sync-mapping|old-campaigns-mapping|hubspot-sync|resellers|find-user)/,
      ),
    });
  }

  return menu;
};

export const SidebarMenu: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const user = useAppSelector(selectCurrentUser);
  const { can, cannot } = usePermission();
  const current = location.pathname;
  const account = useAppSelector(selectCurrentAccount);
  const dispatch = useAppDispatch();

  const isBranchAdmin = branchAdminCheck(user, account);

  const onClick: (url: string) => void = (url: string) => navigate(url);

  useEffect(() => {
    if (!user) return;
    if (
      cannot(Actions.READ, Subjects.ACCOUNTS) &&
      cannot(Actions.READ, Subjects.ASSIGNED_ACCOUNTS)
    ) {
      dispatch(setCurrentAccount(user.account));
    }
  }, [user]);

  return (
    <StyledContainer>
      <div>
        {(can(Actions.READ, Subjects.ACCOUNTS) &&
          cannot(Actions.READ, Subjects.OWN_ACCOUNT)) ||
        can(Actions.READ, Subjects.ASSIGNED_ACCOUNTS) ? (
          <SidebarAccountSelect />
        ) : (
          <SidebarAccountName />
        )}
        {/* TODO: temporary solution, delete when the problem with free trials is fixed */}
        {!!account?.freeTrialEndsAt && user?.account.isSystem && (
          <StyledAppChip label={t('account.freeTrial')} type="secondary" />
        )}
        {/* */}
        <StyledPanelMenu
          model={getMenu(
            can,
            cannot,
            user?.accountsCount as number,
            onClick,
            current,
            t,
            account,
            isBranchAdmin,
          )}
          className="mt-3"
        />
      </div>
      {account && (
        <StyledFooter>
          <div className="mb-3">
            {t('sidebar.accountId')}
            <br />
            <span>{account.id}</span>
          </div>

          {can(Actions.READ, Subjects.HUBSPOT_ACCOUNT_LINK) &&
            account?.hubspot?.id &&
            account?.hubspot?.url && (
              <div className="mb-3">
                {t('hubspot.companyId')}
                <br />
                <a
                  href={account.hubspot.url}
                  target="_blank"
                  className="mt-3"
                  rel="noopener noreferrer"
                >
                  <span>{account.hubspot.id}</span>
                  <i className="pi pi-external-link x2 ml-2" />
                </a>
              </div>
            )}
        </StyledFooter>
      )}
    </StyledContainer>
  );
};
