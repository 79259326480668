import { equal } from '@/api/helpers';
import { CourseStatusAttemptEnum, MaterialType } from '@/client/courses';
import { useLearnerCourses } from '@/hooks/query';
import { PosterCard } from '@/ui/poster-card';
import { CardGridContainer } from '@/ui/styled-ui';
import { postersColumnsWidth } from '@/utils/helpers';
import React from 'react';
import styled from 'styled-components';
import { LearnerLayout } from '../LearnerLayout';
import { LearnerDashboardPostersTabs } from './LearnerDashboardPostersTabs';

const StyledCardGridContainer = styled(CardGridContainer)`
  width: 100%;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 0;

  @media screen and (max-width: ${postersColumnsWidth.THREE}px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (max-width: ${postersColumnsWidth.TWO}px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: ${postersColumnsWidth.ONE}px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const LearnerDashboardPostersPage: React.FC = () => {
  const { isLoading, learnerCourses: posters } = useLearnerCourses({
    skip: 0,
    type: CourseStatusAttemptEnum.IN_PROGRESS,
    filters: [equal('type', MaterialType.POSTER)],
    staleTime: Infinity,
  });

  return (
    <LearnerLayout isLoading={isLoading}>
      <LearnerDashboardPostersTabs />
      <StyledCardGridContainer>
        {posters?.result.map((poster) => (
          <PosterCard key={poster.id} poster={poster} />
        ))}
      </StyledCardGridContainer>
    </LearnerLayout>
  );
};
