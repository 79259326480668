import { processListingParams } from '@/api/helpers';
import { getExceptionFromAxiosError } from '@/client/helpers';
import { MessageResponseModel } from '@/client/models';
import {
  ExportAccountUsersRequest,
  ListUserCoursesRequest,
  ListUserCoursesResponse,
  ListUsersRequest,
  ListUsersResponse,
  SaveUserRequest,
  UpdateUserRequest,
  User,
} from '@/client/users';
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { omit } from 'lodash';

export const usersService = (client: AxiosInstance) => {
  const USERS_BASE_URL = '/users';
  const ACCOUNT_USERS_BASE_URL = (accountId?: string) =>
    `accounts/${accountId}/users`;

  const getUsers = async (
    params: ListUsersRequest & { withBranches?: boolean },
  ): Promise<ListUsersResponse> => {
    try {
      const result = await client.get<
        ListUsersResponse,
        AxiosResponse<ListUsersResponse>
      >(USERS_BASE_URL, {
        withCredentials: true,
        params: {
          ...processListingParams(omit(params, 'withBranches')),
          withBranches: params.withBranches ? 1 : 0,
        },
      });

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const getAccountUsers = async (
    params: ListUsersRequest & { withBranches?: boolean },
    accountId?: string,
  ): Promise<ListUsersResponse> => {
    try {
      const result = await client.get<
        ListUsersResponse,
        AxiosResponse<ListUsersResponse>
      >(ACCOUNT_USERS_BASE_URL(accountId), {
        withCredentials: true,
        params: {
          ...processListingParams(omit(params, 'withBranches')),
          withBranches: params.withBranches ? 1 : 0,
        },
      });

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const getUser = async (
    userId?: string,
    accountId?: string,
  ): Promise<User> => {
    try {
      const result = await client.get<User, AxiosResponse<User>>(
        `${ACCOUNT_USERS_BASE_URL(accountId)}/${userId}`,
        {
          withCredentials: true,
        },
      );

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const saveUser = async (request: SaveUserRequest): Promise<User> => {
    try {
      const result = await client.post<User, AxiosResponse<User>>(
        ACCOUNT_USERS_BASE_URL(request.accountId),
        request,
        {
          withCredentials: true,
        },
      );

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const updateUser = async (request: UpdateUserRequest): Promise<User> => {
    try {
      const result = await client.patch<User, AxiosResponse<User>>(
        `${ACCOUNT_USERS_BASE_URL(request.accountId)}/${request.userId}`,
        request.updates,
        {
          withCredentials: true,
        },
      );

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const deleteUser = async (
    userId: string,
    accountId: string,
  ): Promise<MessageResponseModel> => {
    try {
      const result = await client.delete<
        MessageResponseModel,
        AxiosResponse<MessageResponseModel>
      >(`${ACCOUNT_USERS_BASE_URL(accountId)}/${userId}`, {
        withCredentials: true,
      });

      return new MessageResponseModel(result.data);
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const exportUsers = async (
    request: ExportAccountUsersRequest,
  ): Promise<MessageResponseModel> => {
    try {
      const result = await client.post<
        MessageResponseModel,
        AxiosResponse<MessageResponseModel>
      >(
        `${ACCOUNT_USERS_BASE_URL(request.accountId)}/export`,
        { ...request },
        {
          withCredentials: true,
        },
      );

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const getUserCourses = async (
    params: ListUserCoursesRequest,
    accountId?: string,
    userId?: string,
  ): Promise<ListUserCoursesResponse> => {
    try {
      const result = await client.get<
        ListUserCoursesResponse,
        AxiosResponse<ListUserCoursesResponse>
      >(`${ACCOUNT_USERS_BASE_URL(accountId)}/${userId}/courses`, {
        withCredentials: true,
        params: processListingParams(params),
      });

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const userNotifyUrl = (userId: string, accountId: string) =>
    `${client.defaults.baseURL}accounts/${accountId}/users/${userId}/notify`;

  return {
    getUsers,
    getAccountUsers,
    getUser,
    saveUser,
    updateUser,
    deleteUser,
    exportUsers,
    getUserCourses,
    userNotifyUrl,
  };
};
