import { AppUnauthorized } from '@/app';
import { Redirect } from '@/app/Redirect';
import { CompleteSignIn } from '@/auth';
import { AuthProtected } from '@/auth/components';
import { CourseStatusAttemptEnum, MaterialType } from '@/client/courses';
import { Actions, Subjects, SystemRoles } from '@/client/users';
import { AppRoutesPaths } from '@/common/constants';
import { useAppSelector } from '@/hooks/store';
import { useMixpanel } from '@/hooks/useMixpanel';
import { usePermission } from '@/hooks/usePermission';
import { MainLayout } from '@/layout';
import {
  AccountAzureADPage,
  AccountCoursesPage,
  AccountGoPhish,
  AccountHubspot,
  AccountIntegrationsPage,
  AccountMigrationPage,
  AccountsPage,
  AccountSSOPage,
  CreateUpdateAccountPage,
} from '@/pages/accounts';
import { AccountDocumentsPage } from '@/pages/accounts/AccountDocumentsPage';
import {
  BranchesImport,
  BranchesPage,
  BranchUsers,
  CreateBranchPage,
  UpdateBranchPage,
} from '@/pages/branches';
import {
  CampaignsCatalogueListPage,
  CampaignsPage,
  CampaignsReportPage,
  UpdateCampaignPage,
} from '@/pages/campaigns';
import {
  AccountCourseSchedulePage,
  CourseAccountsPage,
  CourseBranchesPage,
  CourseContentPage,
  CourseGroupsPage,
  CoursesAnalytics,
  CourseSchedulePage,
  CourseScormPreviewPage,
  CoursesPage,
  CourseUsersPage,
  CreateCoursePage,
} from '@/pages/courses';
import { AccountTrainingPlannerPage } from '@/pages/courses/AccountTrainingPlannerPage';
import { DocumentViewPage } from '@/pages/courses/CourseDocumentViewPage';
import {
  AdminDashboardPage,
  LearnerDashboardActivePage,
  LearnerDashboardCompletedPage,
  LearnerDashboardMaterialsPage,
} from '@/pages/dashboards';
import { EfrontSyncWizardPage } from '@/pages/efront-sync';
import {
  EmailTemplatesCreatePage,
  EmailTemplatesCustomPage,
  EmailTemplatesPage,
  EmailTemplatesUpdatePage,
} from '@/pages/email-templates';
import { FindUserPage } from '@/pages/find-user';
import {
  CreateGroupPage,
  GroupsPage,
  GroupUsers,
  UpdateGroupPage,
} from '@/pages/groups';
import { HubspotSyncPage } from '@/pages/hubspot-sync';
import {
  LandingPageTemplatesCreatePage,
  LandingPageTemplatesCustomPage,
  LandingPageTemplatesPage,
  LandingPageTemplatesUpdatePage,
} from '@/pages/landing-page-templates';
import LogoutPage from '@/pages/LogoutPage';
import { ProfileSettingsPage } from '@/pages/me/ProfileSettingsPage';
import { NotFoundPage } from '@/pages/not-found';
import { OldCampaignsWizardPage } from '@/pages/old-campaigns-sync/OldCampaignsWizardPage';
import {
  RedirectPageTemplatesCreatePage,
  RedirectPageTemplatesCustomPage,
  RedirectPageTemplatesPage,
  RedirectPageTemplatesUpdatePage,
} from '@/pages/redirect-page-templates';
import { AwarenessReportsPage, SummaryReportsPage } from '@/pages/reports';
import {
  AccountsToAResellerPage,
  CreateResellersPage,
  ResellersPage,
  SystemResellerPage,
  UpdateResellerPage,
} from '@/pages/resellers';
import {
  MaterialCreatePage,
  MaterialEditPage,
  MaterialsPage,
} from '@/pages/resources';
import { SecurityCulturePage } from '@/pages/security-culture';
import {
  CreateSenderProfilePage,
  SenderProfilesCustomPage,
  SenderProfilesPage,
  UpdateSenderProfilePage,
} from '@/pages/sender-profiles';
import {
  CreateUserPage,
  UpdateUserPage,
  UserCoursesPage,
  UserPropertiesPage,
  UsersPage,
} from '@/pages/users';
import { UserDocumentsPage } from '@/pages/users/UserDocumentsPage';
import { ScormCourseInfoPage, ScormPlayerPage } from '@/scorm';
import { selectCurrentAccount } from '@/store/features/account';
import { selectCurrentUser } from '@/store/features/users';
import {
  SystemEmailsCreateTypeConfigPage,
  SystemEmailsSendingConfigPage,
  SystemEmailsTemplatesCreatePage,
  SystemEmailsTemplatesUpdatePage,
  SystemEmailsTypesConfigsPage,
  SystemEmailsUpdateTypeConfigPage,
  SystemEmailTemplatesPage,
} from '@/system-settings';
import {
  branchAdminCheck,
  isOutsideOfEfront,
  isPhishingEnabled,
  isUserManagementEnabled,
} from '@/utils/helpers';
import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { AppDashboardTypeSetter } from './AppDashboardTypeSetter';
import { AppHubspotListener } from './AppHubspotListener';

const AppRouter = () => {
  const { can, cannot } = usePermission();
  const currentUser = useAppSelector(selectCurrentUser);
  const currentAccount = useAppSelector(selectCurrentAccount);
  const location = useLocation();
  const { track } = useMixpanel();
  const isBranchAdmin = branchAdminCheck(currentUser, currentAccount);
  // Keep track of the last viewed page
  const [prevPath, setPrevPath] = useState(location.pathname);

  useEffect(() => {
    // Only track pages with Mixpanel when the path changes, ignore queries
    if (
      location.pathname !== prevPath &&
      currentUser?.account?.isTrackingEnabled
    ) {
      track('Page view', {
        'Page Name': document.title,
        Path: location.pathname,
      });

      setPrevPath(location.pathname);
    }
  }, [location, prevPath]);

  return (
    <Routes>
      <Route path={AppRoutesPaths.BASE} element={<AuthProtected />}>
        <Route path={AppRoutesPaths.BASE} element={<AppHubspotListener />}>
          <Route path={AppRoutesPaths.BASE} element={<MainLayout />}>
            <Route path={AppRoutesPaths.BASE} element={<Redirect />} />

            {/* Admin Dashboard Routes */}
            <Route element={<AppDashboardTypeSetter isAdmin={true} />}>
              {(isOutsideOfEfront() || isUserManagementEnabled()) &&
                can(Actions.READ, Subjects.EFRONT_SYNC) && (
                  <Route
                    path={AppRoutesPaths.EFRONT_SYNC_ROUTE}
                    element={<EfrontSyncWizardPage />}
                  />
                )}
              {(isOutsideOfEfront() || isPhishingEnabled()) &&
                can(Actions.READ, Subjects.CAMPAIGNS) && (
                  <Route
                    path={AppRoutesPaths.OLD_CAMPAIGNS_WIZARD_ROUTE}
                    element={<OldCampaignsWizardPage />}
                  />
                )}
              {(isOutsideOfEfront() || isPhishingEnabled()) &&
                can(Actions.READ, Subjects.HUBSPOT_ACCOUNT_LINK) && (
                  <Route
                    path={AppRoutesPaths.HUBSPOT_SYNC_ROUTE}
                    element={<HubspotSyncPage />}
                  />
                )}
              {isOutsideOfEfront() &&
                can(Actions.READ, Subjects.USERS) &&
                currentUser?.account.isSystem && (
                  <Route
                    path={AppRoutesPaths.FIND_USER_ROUTE}
                    element={<FindUserPage />}
                  />
                )}
              {isPhishingEnabled() && isOutsideOfEfront() && (
                <>
                  {can(Actions.READ, Subjects.SYSTEM_SETTINGS) && (
                    <>
                      <Route
                        path={AppRoutesPaths.SYSTEM_EMAILS_TEMPLATES_ROUTE}
                        element={<SystemEmailTemplatesPage />}
                      />
                      <Route
                        path={
                          AppRoutesPaths.SYSTEM_EMAILS_SENDING_CONFIGS_ROUTE
                        }
                        element={<SystemEmailsSendingConfigPage />}
                      />
                      <Route
                        path={AppRoutesPaths.SYSTEM_EMAILS_TYPES_CONFIGS_ROUTE}
                        element={<SystemEmailsTypesConfigsPage />}
                      />
                    </>
                  )}
                  {can(Actions.READ, Subjects.SECURITY_CULTURE) && (
                    <Route
                      path={AppRoutesPaths.SECURITY_CULTURE_ROUTE}
                      element={<SecurityCulturePage />}
                    />
                  )}
                  {can(Actions.CREATE, Subjects.SYSTEM_SETTINGS) && (
                    <>
                      <Route
                        path={
                          AppRoutesPaths.SYSTEM_EMAILS_TEMPLATES_CREATE_ROUTE
                        }
                        element={<SystemEmailsTemplatesCreatePage />}
                      />
                      <Route
                        path={
                          AppRoutesPaths.SYSTEM_EMAILS_TYPES_CONFIGS_CREATE_ROUTE
                        }
                        element={<SystemEmailsCreateTypeConfigPage />}
                      />
                    </>
                  )}

                  {can(Actions.UPDATE, Subjects.SYSTEM_SETTINGS) && (
                    <>
                      <Route
                        path={AppRoutesPaths.SYSTEM_EMAILS_TEMPLATES_EDIT_ROUTE}
                        element={<SystemEmailsTemplatesUpdatePage />}
                      />
                      <Route
                        path={
                          AppRoutesPaths.SYSTEM_EMAILS_TYPES_CONFIGS_EDIT_ROUTE
                        }
                        element={<SystemEmailsUpdateTypeConfigPage />}
                      />
                    </>
                  )}
                </>
              )}
              {isUserManagementEnabled() && (
                <>
                  {/* Users Routes */}
                  {can(Actions.READ, Subjects.USERS) && (
                    <Route
                      path={AppRoutesPaths.USERS_ROUTE}
                      element={<UsersPage />}
                    />
                  )}
                  {can(Actions.CREATE, Subjects.USERS) &&
                    (!currentAccount?.isSystem ||
                      currentUser?.role.code === SystemRoles.DEVELOPER) && (
                      <Route
                        path={AppRoutesPaths.USERS_CREATE_ROUTE}
                        element={<CreateUserPage />}
                      />
                    )}
                  {can(Actions.UPDATE, Subjects.USERS) && (
                    <Route
                      path={AppRoutesPaths.USERS_EDIT_ROUTE}
                      element={<UpdateUserPage />}
                    />
                  )}
                  {can(Actions.READ, Subjects.USERS) && (
                    <>
                      <Route
                        path={AppRoutesPaths.USERS_COURSES_ROUTE}
                        element={<UserCoursesPage />}
                      />
                      <Route
                        path={AppRoutesPaths.USERS_DOCUMENTS_ROUTE}
                        element={<UserDocumentsPage />}
                      />
                      <Route
                        path={AppRoutesPaths.USERS_PROPERTIES_ROUTE}
                        element={<UserPropertiesPage />}
                      />
                    </>
                  )}

                  {/* Branch Routes */}
                  {can(Actions.READ, Subjects.BRANCHES) && (
                    <Route
                      path={AppRoutesPaths.BRANCHES_ROUTE}
                      element={<BranchesPage />}
                    />
                  )}
                  {can(Actions.CREATE, Subjects.BRANCHES) &&
                    (!currentAccount?.isSystem ||
                      currentUser?.role.code === SystemRoles.DEVELOPER) && (
                      <Route
                        path={AppRoutesPaths.BRANCHES_CREATE_ROUTE}
                        element={<CreateBranchPage />}
                      />
                    )}
                  {can(Actions.UPDATE, Subjects.BRANCHES) && (
                    <Route
                      path={AppRoutesPaths.BRANCHES_EDIT_ROUTE}
                      element={<UpdateBranchPage />}
                    />
                  )}
                  {can(Actions.READ, Subjects.BRANCHES) && (
                    <Route
                      path={AppRoutesPaths.BRANCHES_USERS_ROUTE}
                      element={<BranchUsers />}
                    />
                  )}
                  {can(Actions.READ, Subjects.BRANCHES) && (
                    <Route
                      path={AppRoutesPaths.BRANCHES_USERS_IMPORT_ROUTE}
                      element={<BranchesImport />}
                    />
                  )}
                </>
              )}
              {/* Resellers Routes */}
              {isOutsideOfEfront() && (
                <>
                  {can(Actions.READ, Subjects.RESELLERS) && (
                    <Route
                      path={AppRoutesPaths.RESELLERS_ROUTE}
                      element={<ResellersPage />}
                    />
                  )}
                  {can(Actions.CREATE, Subjects.RESELLERS) &&
                    currentUser?.role.code === SystemRoles.DEVELOPER && (
                      <Route
                        path={AppRoutesPaths.RESELLERS_CREATE_ROUTE}
                        element={<CreateResellersPage />}
                      />
                    )}
                  {can(Actions.UPDATE, Subjects.RESELLERS) && (
                    <Route
                      path={AppRoutesPaths.RESELLERS_EDIT_ROUTE}
                      element={<UpdateResellerPage />}
                    />
                  )}
                  {can(Actions.READ, Subjects.RESELLERS) && (
                    <Route
                      path={AppRoutesPaths.RESELLERS_ACCOUNTS_ROUTE}
                      element={<AccountsToAResellerPage />}
                    />
                  )}
                  {can(Actions.UPDATE, Subjects.RESELLERS) && (
                    <Route
                      path={AppRoutesPaths.RESELLERS_SYSTEM_ROUTE}
                      element={<SystemResellerPage />}
                    />
                  )}
                </>
              )}
              {/* Accounts Routes */}
              {can(Actions.READ, Subjects.ACCOUNTS) &&
                cannot(Actions.READ, Subjects.OWN_ACCOUNT) && (
                  <Route
                    path={AppRoutesPaths.ACCOUNTS_ROUTE}
                    element={<AccountsPage />}
                  />
                )}
              {(can(Actions.READ, Subjects.ACCOUNTS) ||
                can(Actions.READ, Subjects.OWN_ACCOUNT) ||
                can(Actions.READ, Subjects.ASSIGNED_ACCOUNTS)) && (
                <Route
                  path={AppRoutesPaths.ACCOUNT_ROUTE}
                  element={<CreateUpdateAccountPage />}
                />
              )}
              {can(Actions.CREATE, Subjects.ACCOUNTS) && (
                <Route
                  path={AppRoutesPaths.ACCOUNTS_CREATE_ROUTE}
                  element={<CreateUpdateAccountPage />}
                />
              )}
              {can(Actions.UPDATE, Subjects.ACCOUNTS) && (
                <Route
                  path={AppRoutesPaths.ACCOUNTS_UPDATE_ROUTE}
                  element={<CreateUpdateAccountPage />}
                />
              )}
              {can(Actions.READ, Subjects.ACCOUNTS) && (
                <Route
                  path={AppRoutesPaths.ACCOUNTS_INTEGRATIONS_ROUTE}
                  element={<AccountIntegrationsPage />}
                />
              )}
              {can(Actions.READ, Subjects.ACCOUNTS) && (
                <Route
                  path={AppRoutesPaths.ACCOUNTS_INTEGRATIONS_GO_PHISH_ROUTE}
                  element={<AccountGoPhish />}
                />
              )}
              {can(Actions.READ, Subjects.ACCOUNTS) && (
                <Route
                  path={AppRoutesPaths.ACCOUNTS_INTEGRATIONS_HUBSPOT_ROUTE}
                  element={<AccountHubspot />}
                />
              )}
              {can(Actions.READ, Subjects.AD_SYNC) && !isBranchAdmin && (
                <Route
                  path={AppRoutesPaths.ACCOUNT_AZURE_AD_ROUTE}
                  element={<AccountAzureADPage />}
                />
              )}
              {can(Actions.READ, Subjects.ACCOUNT_SSO) &&
                !isBranchAdmin &&
                isOutsideOfEfront() && (
                  <Route
                    path={AppRoutesPaths.ACCOUNT_SSO_ROUTE}
                    element={<AccountSSOPage />}
                  />
                )}
              {can(Actions.READ, Subjects.ACCOUNT_MIGRATION) &&
                can(Actions.READ, Subjects.USERS_MIGRATION) && (
                  <Route
                    path={AppRoutesPaths.ACCOUNT_MIGRATION_ROUTE}
                    element={<AccountMigrationPage />}
                  />
                )}
              {can(Actions.CREATE, Subjects.COURSE_ACCOUNT_AVAILABILITY) &&
                isOutsideOfEfront() && (
                  <>
                    <Route
                      path={AppRoutesPaths.ACCOUNT_COURSES_ROUTE}
                      element={<AccountCoursesPage />}
                    />
                    <Route
                      path={AppRoutesPaths.ACCOUNT_SECURITY_DOCUMENTS_ROUTE}
                      element={<AccountDocumentsPage />}
                    />
                  </>
                )}
              {/* {can(Actions.READ, Subjects.ACCOUNTS) && (
              <Route
                path={AppRoutesPaths.ACCOUNT_SAML_CONFIG_ROUTE}
                element={<AccountsSAMLConfigPage />}
              />
            )} */}
              {/* Groups Routes */}
              {isUserManagementEnabled() && (
                <>
                  {can(Actions.READ, Subjects.GROUPS) && !isBranchAdmin && (
                    <Route
                      path={AppRoutesPaths.GROUPS_ROUTE}
                      element={<GroupsPage />}
                    />
                  )}
                  {can(Actions.CREATE, Subjects.GROUPS) &&
                    !isBranchAdmin &&
                    (!currentAccount?.isSystem ||
                      currentUser?.role.code === SystemRoles.DEVELOPER) && (
                      <Route
                        path={AppRoutesPaths.GROUPS_CREATE_ROUTE}
                        element={<CreateGroupPage />}
                      />
                    )}
                  {can(Actions.UPDATE, Subjects.GROUPS) && !isBranchAdmin && (
                    <Route
                      path={AppRoutesPaths.GROUPS_EDIT_ROUTE}
                      element={<UpdateGroupPage />}
                    />
                  )}
                  {can(Actions.READ, Subjects.GROUPS) && !isBranchAdmin && (
                    <Route
                      path={AppRoutesPaths.GROUPS_USERS_ROUTE}
                      element={<GroupUsers />}
                    />
                  )}
                </>
              )}
              {isPhishingEnabled() && (
                <>
                  {/* Campaign Routes */}
                  {can(Actions.READ, Subjects.CAMPAIGNS) && (
                    <Route
                      path={AppRoutesPaths.CAMPAIGNS_ROUTE}
                      element={<CampaignsPage />}
                    />
                  )}
                  {/* TODO: remove currentUser's account check when templates are set on prod */}
                  {can(Actions.READ, Subjects.CAMPAIGNS) &&
                    currentUser?.account.isSystem && (
                      <Route
                        path={AppRoutesPaths.CAMPAIGNS_CATALOGUE_ROUTE}
                        element={<CampaignsCatalogueListPage />}
                      />
                    )}
                  {can(Actions.UPDATE, Subjects.CAMPAIGNS) && (
                    <Route
                      path={AppRoutesPaths.CAMPAIGNS_EDIT_ROUTE}
                      element={<UpdateCampaignPage />}
                    />
                  )}
                  {can(Actions.READ, Subjects.CAMPAIGN_RESULTS) && (
                    <Route
                      path={AppRoutesPaths.CAMPAIGNS_REPORT_ROUTE}
                      element={<CampaignsReportPage />}
                    />
                  )}
                  {/* Landing Page templates Routes */}
                  {can(Actions.READ, Subjects.PAGES) && (
                    <>
                      <Route
                        path={
                          AppRoutesPaths.LANDING_PAGE_TEMPLATES_PREDEFINED_ROUTE
                        }
                        element={<LandingPageTemplatesPage />}
                      />
                      <Route
                        path={
                          AppRoutesPaths.LANDING_PAGE_TEMPLATES_CUSTOM_ROUTE
                        }
                        element={<LandingPageTemplatesCustomPage />}
                      />
                    </>
                  )}
                  {can(Actions.CREATE, Subjects.PAGES) && (
                    <Route
                      path={AppRoutesPaths.LANDING_PAGE_TEMPLATES_CREATE_ROUTE}
                      element={<LandingPageTemplatesCreatePage />}
                    />
                  )}
                  {can(Actions.UPDATE, Subjects.PAGES) && (
                    <Route
                      path={AppRoutesPaths.LANDING_PAGE_TEMPLATES_UPDATE_ROUTE}
                      element={<LandingPageTemplatesUpdatePage />}
                    />
                  )}
                  {/* Redirect Page templates Routes */}
                  {can(Actions.READ, Subjects.PAGES) && (
                    <>
                      <Route
                        path={
                          AppRoutesPaths.REDIRECT_PAGE_TEMPLATES_PREDEFINED_ROUTE
                        }
                        element={<RedirectPageTemplatesPage />}
                      />
                      <Route
                        path={
                          AppRoutesPaths.REDIRECT_PAGE_TEMPLATES_CUSTOM_ROUTE
                        }
                        element={<RedirectPageTemplatesCustomPage />}
                      />
                    </>
                  )}
                  {can(Actions.CREATE, Subjects.PAGES) && (
                    <Route
                      path={AppRoutesPaths.REDIRECT_PAGE_TEMPLATES_CREATE_ROUTE}
                      element={<RedirectPageTemplatesCreatePage />}
                    />
                  )}
                  {can(Actions.UPDATE, Subjects.PAGES) && (
                    <Route
                      path={AppRoutesPaths.REDIRECT_PAGE_TEMPLATES_UPDATE_ROUTE}
                      element={<RedirectPageTemplatesUpdatePage />}
                    />
                  )}
                  {/* Email templates Routes */}
                  {can(Actions.READ, Subjects.EMAIL_TEMPLATES) && (
                    <>
                      <Route
                        path={AppRoutesPaths.EMAIL_TEMPLATES_PREDEFINED_ROUTE}
                        element={<EmailTemplatesPage />}
                      />
                      <Route
                        path={AppRoutesPaths.EMAIL_TEMPLATES_CUSTOM_ROUTE}
                        element={<EmailTemplatesCustomPage />}
                      />
                    </>
                  )}
                  {can(Actions.CREATE, Subjects.EMAIL_TEMPLATES) && (
                    <Route
                      path={AppRoutesPaths.EMAIL_TEMPLATES_CREATE_ROUTE}
                      element={<EmailTemplatesCreatePage />}
                    />
                  )}
                  {can(Actions.UPDATE, Subjects.EMAIL_TEMPLATES) && (
                    <Route
                      path={AppRoutesPaths.EMAIL_TEMPLATES_UPDATE_ROUTE}
                      element={<EmailTemplatesUpdatePage />}
                    />
                  )}
                  {/* Sender Profiles Routes */}
                  {can(Actions.READ, Subjects.SENDING_PROFILES) && (
                    <>
                      <Route
                        path={AppRoutesPaths.SENDER_PROFILES_PREDEFINED_ROUTE}
                        element={<SenderProfilesPage />}
                      />
                      <Route
                        path={AppRoutesPaths.SENDER_PROFILES_CUSTOM_ROUTE}
                        element={<SenderProfilesCustomPage />}
                      />
                    </>
                  )}
                  {can(Actions.CREATE, Subjects.SENDING_PROFILES) && (
                    <Route
                      path={AppRoutesPaths.SENDER_PROFILES_CREATE_ROUTE}
                      element={<CreateSenderProfilePage />}
                    />
                  )}
                  {can(Actions.UPDATE, Subjects.SENDING_PROFILES) && (
                    <Route
                      path={AppRoutesPaths.SENDER_PROFILES_EDIT_ROUTE}
                      element={<UpdateSenderProfilePage />}
                    />
                  )}
                </>
              )}
              {isOutsideOfEfront() && (
                <>
                  {/* Dashboard Routes */}
                  {can(Actions.READ, Subjects.ADMIN_DASHBOARD) && (
                    <Route
                      path={AppRoutesPaths.ADMIN_DASHBOARD_ROUTE}
                      element={<AdminDashboardPage />}
                    />
                  )}

                  {/* User profile */}
                  {can(Actions.UPDATE, Subjects.ME) && (
                    <Route
                      path={AppRoutesPaths.PROFILE_SETTINGS_ROUTE}
                      element={<ProfileSettingsPage />}
                    />
                  )}

                  {/* Courses Routes */}
                  {can(Actions.READ, Subjects.COURSES) && (
                    <Route
                      path={AppRoutesPaths.COURSES_ROUTE}
                      element={<CoursesPage />}
                    />
                  )}
                  {can(Actions.CREATE, Subjects.COURSES) && (
                    <Route
                      path={AppRoutesPaths.COURSES_CREATE_ROUTE}
                      element={<CreateCoursePage />}
                    />
                  )}
                  {/* TODO: Add correct permissions */}
                  {can(Actions.READ, Subjects.COURSES) && (
                    <>
                      <Route
                        path={AppRoutesPaths.COURSES_CONTENT_ROUTE}
                        element={<CourseContentPage />}
                      />
                      <Route
                        path={AppRoutesPaths.COURSES_SCHEDULE_ROUTE}
                        element={<CourseSchedulePage />}
                      />
                      <Route
                        path={AppRoutesPaths.COURSES_ACCOUNTS_ROUTE}
                        element={<CourseAccountsPage />}
                      />
                      <Route
                        path={AppRoutesPaths.COURSES_BRANCHES_ROUTE}
                        element={<CourseBranchesPage />}
                      />
                      <Route
                        path={AppRoutesPaths.COURSES_GROUPS_ROUTE}
                        element={<CourseGroupsPage />}
                      />
                      <Route
                        path={AppRoutesPaths.COURSES_USERS_ROUTE}
                        element={<CourseUsersPage type={MaterialType.COURSE} />}
                      />
                      <Route
                        path={AppRoutesPaths.COURSES_SCORM_PREVIEW_ROUTE}
                        element={<CourseScormPreviewPage />}
                      />
                      <Route
                        path={AppRoutesPaths.COURSES_ANALYTICS_ROUTE}
                        element={<CoursesAnalytics />}
                      />
                    </>
                  )}
                  {can(Actions.READ, Subjects.COURSE_ACCOUNT_SCHEDULES) && (
                    <Route
                      path={AppRoutesPaths.ACCOUNT_COURSE_EVENT_LOG}
                      element={<AccountCourseSchedulePage />}
                    />
                  )}

                  {can(Actions.READ, Subjects.COURSE_PLANNER) && (
                    <Route
                      path={AppRoutesPaths.ACCOUNT_COURSE_PLANNER_ROUTE}
                      element={<AccountTrainingPlannerPage />}
                    />
                  )}

                  {/* Materials Routes */}
                  {can(Actions.READ, Subjects.COURSES) && (
                    <Route
                      path={AppRoutesPaths.RESOURCES_ROUTE}
                      element={<MaterialsPage type={MaterialType.RESOURCE} />}
                    />
                  )}
                  {can(Actions.READ, Subjects.COURSES) &&
                    currentAccount?.meta?.enableMaterialsUpload && (
                      <Route
                        path={AppRoutesPaths.CUSTOM_MATERIALS_ROUTE}
                        element={
                          <MaterialsPage type={MaterialType.CUSTOM_MATERIAL} />
                        }
                      />
                    )}
                  {can(Actions.CREATE, Subjects.COURSES) && (
                    <Route
                      path={AppRoutesPaths.CUSTOM_MATERIALS_CREATE_ROUTE}
                      element={
                        <MaterialCreatePage
                          type={MaterialType.CUSTOM_MATERIAL}
                        />
                      }
                    />
                  )}
                  {can(Actions.READ, Subjects.COURSES) && (
                    <Route
                      path={AppRoutesPaths.CUSTOM_MATERIALS_EDIT_ROUTE}
                      element={
                        <MaterialEditPage type={MaterialType.CUSTOM_MATERIAL} />
                      }
                    />
                  )}
                  {can(Actions.CREATE, Subjects.COURSES) && (
                    <Route
                      path={AppRoutesPaths.RESOURCES_CREATE_ROUTE}
                      element={
                        <MaterialCreatePage type={MaterialType.RESOURCE} />
                      }
                    />
                  )}
                  {can(Actions.READ, Subjects.COURSES) && (
                    <Route
                      path={AppRoutesPaths.RESOURCES_EDIT_ROUTE}
                      element={
                        <MaterialEditPage type={MaterialType.RESOURCE} />
                      }
                    />
                  )}
                  {/* TODO: Uncomment when we have schedule for documents */}
                  {/* {can(Actions.READ, Subjects.COURSE_ACCOUNT_SCHEDULES) && (
                    <Route
                      path={AppRoutesPaths.ACCOUNT_DOCUMENTS_SCHEDULE_ROUTE}
                      element={<AccountCourseSchedulePage isMaterial />}
                    />
                  )} */}
                  {can(Actions.READ, Subjects.COURSES) && (
                    <>
                      <Route
                        path={AppRoutesPaths.RESOURCES_SCHEDULE_ROUTE}
                        element={<CourseSchedulePage isMaterial />}
                      />
                      <Route
                        path={AppRoutesPaths.CUSTOM_MATERIALS_SCHEDULE_ROUTE}
                        element={<CourseSchedulePage isMaterial />}
                      />
                      <Route
                        path={AppRoutesPaths.RESOURCES_ACCOUNTS_ROUTE}
                        element={<CourseAccountsPage isMaterial />}
                      />
                      <Route
                        path={AppRoutesPaths.MATERIALS_BRANCHES_ROUTE}
                        element={<CourseBranchesPage isMaterial />}
                      />
                      <Route
                        path={AppRoutesPaths.MATERIALS_GROUPS_ROUTE}
                        element={<CourseGroupsPage isMaterial />}
                      />
                      <Route
                        path={AppRoutesPaths.CUSTOM_MATERIALS_USERS_ROUTE}
                        element={
                          <CourseUsersPage
                            type={MaterialType.CUSTOM_MATERIAL}
                          />
                        }
                      />
                      <Route
                        path={AppRoutesPaths.RESOURCES_USERS_ROUTE}
                        element={
                          <CourseUsersPage type={MaterialType.RESOURCE} />
                        }
                      />
                    </>
                  )}
                  {/* TODO: Uncomment when we have posters */}
                  {/* {can(Actions.READ, Subjects.COURSES) && (
                    <Route
                      path={AppRoutesPaths.POSTERS_ROUTE}
                      element={<PostersPage />}
                    />
                  )}
                  {can(Actions.CREATE, Subjects.COURSES) && (
                    <Route
                      path={AppRoutesPaths.POSTER_CREATE_ROUTE}
                      element={<PosterCreatePage />}
                    />
                  )}
                  {can(Actions.READ, Subjects.COURSES) && (
                    <Route
                      path={AppRoutesPaths.POSTER_EDIT_ROUTE}
                      element={<PosterEditPage />}
                    />
                  )} */}
                </>
              )}
              {/* USER PROFILE */}
              {can(Actions.UPDATE, Subjects.ME) && (
                <Route
                  path={AppRoutesPaths.PROFILE_SETTINGS_ROUTE}
                  element={<ProfileSettingsPage />}
                />
              )}
              {/* Reports */}
              {can(Actions.READ, Subjects.ACCOUNT_REPORTS) && (
                <>
                  <Route
                    path={AppRoutesPaths.REPORTS_SUMMARY_ROUTE}
                    element={<SummaryReportsPage />}
                  />
                  <Route
                    path={AppRoutesPaths.REPORTS_AWARENESS_ROUTE}
                    element={<AwarenessReportsPage />}
                  />
                </>
              )}
            </Route>

            {/* Learners Dashboard Routes */}
            {isOutsideOfEfront() && (
              <Route element={<AppDashboardTypeSetter isAdmin={false} />}>
                <Route
                  path={AppRoutesPaths.LEARNER_DASHBOARD_ACTIVE_ROUTE}
                  element={<LearnerDashboardActivePage />}
                />
                <Route
                  path={AppRoutesPaths.LEARNER_DASHBOARD_COMPLETED_ROUTE}
                  element={<LearnerDashboardCompletedPage />}
                />
                <Route
                  path={
                    AppRoutesPaths.LEARNER_DASHBOARD_MATERIALS_RESOURCES_ROUTE
                  }
                  element={
                    <LearnerDashboardMaterialsPage
                      type={MaterialType.RESOURCE}
                      status={CourseStatusAttemptEnum.IN_PROGRESS}
                    />
                  }
                />
                {currentUser?.account?.meta?.enableMaterialsUpload && (
                  <>
                    <Route
                      path={
                        AppRoutesPaths.LEARNER_DASHBOARD_MATERIALS_TODO_ROUTE
                      }
                      element={
                        <LearnerDashboardMaterialsPage
                          type={MaterialType.CUSTOM_MATERIAL}
                          status={CourseStatusAttemptEnum.IN_PROGRESS}
                        />
                      }
                    />
                    <Route
                      path={
                        AppRoutesPaths.LEARNER_DASHBOARD_MATERIALS_COMPLETED_ROUTE
                      }
                      element={
                        <LearnerDashboardMaterialsPage
                          type={MaterialType.CUSTOM_MATERIAL}
                          status={CourseStatusAttemptEnum.COMPLETED}
                        />
                      }
                    />
                  </>
                )}
                {/* TODO: Uncomment when we have posters */}
                {/* <Route
                  path={AppRoutesPaths.LEARNER_DASHBOARD_POSTERS_ALL_ROUTE}
                  element={<LearnerDashboardPostersPage />}
                />
                <Route
                  path={AppRoutesPaths.LEARNER_DASHBOARD_POSTERS_COURSE_ROUTE}
                  element={<LearnerDashboardPostersPage />}
                />
                <Route
                  path={AppRoutesPaths.LEARNER_DASHBOARD_POSTERS_CYBER_ROUTE}
                  element={<LearnerDashboardPostersPage />}
                />
                <Route
                  path={AppRoutesPaths.LEARNER_DASHBOARD_POSTERS_GDPR_ROUTE}
                  element={<LearnerDashboardPostersPage />}
                />
                <Route
                  path={
                    AppRoutesPaths.LEARNER_DASHBOARD_POSTERS_INFOGRAPHICS_ROUTE
                  }
                  element={<LearnerDashboardPostersPage />}
                />
                <Route
                  path={
                    AppRoutesPaths.LEARNER_DASHBOARD_POSTERS_INFOSCREEN_ROUTE
                  }
                  element={<LearnerDashboardPostersPage />}
                /> */}
                <Route
                  path={AppRoutesPaths.COURSE_INFO_ROUTE}
                  element={<ScormCourseInfoPage />}
                />
                <Route
                  path={AppRoutesPaths.COURSE_PLAY_ROUTE}
                  element={<ScormPlayerPage />}
                />
                <Route
                  path={AppRoutesPaths.DOCUMENT_VIEW_ROUTE}
                  element={<DocumentViewPage />}
                />
                {can(Actions.UPDATE, Subjects.ME) && (
                  <Route
                    path={AppRoutesPaths.LEARNER_PROFILE_SETTINGS_ROUTE}
                    element={<ProfileSettingsPage />}
                  />
                )}
              </Route>
            )}

            {/* Catch all unmatched routes */}
            <Route path="*" element={<NotFoundPage />} />
          </Route>
        </Route>
      </Route>

      <Route
        path={AppRoutesPaths.UNAUTHORIZED_ROUTE}
        element={<AppUnauthorized />}
      />
      <Route
        path={AppRoutesPaths.COMPLETE_KEYCLOAK_SIGN_IN_ROUTE}
        element={<CompleteSignIn />}
      />
      <Route path={AppRoutesPaths.LOGOUT} element={<LogoutPage />} />
    </Routes>
  );
};

export default AppRouter;
