import { PlannerCRUDCourse } from '@/client/planner/types';
import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
// import { CourseScormPreviewModal } from '@/components/planner/modals/CourseScormPreviewModal';
import PagePlaceholderImage from '@/images/placeholders/page-template.svg';
import { DateFormats } from '@/system-settings/enums';
import { AppButton } from '@/ui/buttons';
import { parsePeriodStringToUserReadable } from '@/utils/helpers';
import { t } from 'i18next';
import { escapeRegExp } from 'lodash';
import moment from 'moment';
import { Card } from 'primereact/card';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import React, { useRef } from 'react';
import styled from 'styled-components';

const StyledImageCard = styled(Card)`
  &.p-card {
    width: 160px;
    height: 170px;
    flex-shrink: 0;

    display: flex;
    flex-direction: column;
    border: 1px solid var(--gray-dark);
    border-radius: var(--xsmall-border-radius);
    box-shadow: none;
    overflow: hidden;
    cursor: pointer;
    padding: 0;

    &.past-date {
      opacity: 0.3;
    }

    &:hover,
    &:focus-within {
      border-color: var(--red-main);

      .p-card-header .image-card-overlay {
        opacity: 1;
        cursor: pointer;
      }
    }

    &.disabled {
      opacity: 0.6;
      pointer-events: none !important;
    }

    .p-card-body {
      display: flex;
      flex-direction: column;
      flex: 1;
      padding: var(--small-padding);
      .p-card-title {
        margin-bottom: 0;
        font-size: var(--medium-font-size);
        line-height: var(--medium-line-height);
        color: var(--black-main);
      }
      .p-card-subtitle {
        margin-bottom: 0;
        font-size: var(--xsmall-font-size);
        line-height: var(--xsmall-line-height);
        color: var(--black-main);
      }
    }

    .p-card-header {
      height: 194px;
      position: relative;
      display: flex;
      align-items: stretch;

      .image-card-thumbnail {
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        background-color: var(--gray-main);
      }

      .image-card-overlay {
        position: absolute;
        height: 100%;
        padding: var(--small-padding);
        background: var(--orange-faint);
        opacity: 0;
        transition: all 0.4s;
      }
    }

    .p-card-footer {
      margin-top: auto;
      padding-top: 0;

      span {
        display: block;
        text-align: left;
      }

      span.course-name {
        font-size: var(--small-font-size);
        color: var(--black-main);
        text-wrap: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      span:not(.course-name) {
        font-size: var(--xsmall-font-size);
        color: var(--gray-darker);
      }
    }

    label {
      cursor: pointer;
    }
  }
`;

const StyledAppButton = styled(AppButton)`
  &.p-button.p-button-icon-only {
    padding: var(--small-padding) !important;
    margin: 6px;
    margin-left: auto;
    width: calc(var(--default-padding) + var(--xxsmall-padding));
    height: calc(var(--default-padding) + var(--xxsmall-padding));
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);

    span {
      font-size: 18px;
      font-weight: 100;
    }
  }
`;

type PlannerImageCardProps = {
  item: PlannerCRUDCourse;
  isOnboardingPlan?: boolean;
  isCRUD?: boolean;
  onItemRemove?: (item: PlannerCRUDCourse) => void;
};

export const PlannerImageCard: React.FC<PlannerImageCardProps> = ({
  item,
  isOnboardingPlan,
  isCRUD,
  onItemRemove,
}) => {
  const menuRef = useRef<Menu>(null);
  // const [isPreviewModalVisible, setIsPreviewModalVisible] = useState(false);

  const menuItems: MenuItem[] = [
    {
      label: t('course.view.details'),
      icon: 'pi pi-external-link',
      command: () => {
        window.open(
          RedirectPaths[RedirectPathsEnum.COURSES_CONTENT](item?.id),
          '_blank',
        );
      },
    },
  ];

  const getFooterTemplate = (item: PlannerCRUDCourse) => {
    return (
      <>
        <span className="course-name">{item.name}</span>

        {!isCRUD && (
          <>
            {!isOnboardingPlan ? (
              <span>
                {moment(item.enrollDate).format(DateFormats.TIMEDATE_SHORT)}
              </span>
            ) : (
              <span>
                {parsePeriodStringToUserReadable(
                  item.periodValue,
                  item.periodUnit,
                )}
              </span>
            )}
          </>
        )}
      </>
    );
  };

  const getHeaderTemplate = (item: PlannerCRUDCourse) => {
    return (
      <div
        className="flex image-card-thumbnail flex-1"
        style={{
          backgroundImage: `url(${escapeRegExp(
            item?.thumbnailUrl ||
              item?.courseThumbnail?.signedUrl ||
              PagePlaceholderImage,
          )}`,
        }}
      >
        {isCRUD && onItemRemove && !item.isPastDate && !item.executedAt && (
          <StyledAppButton
            icon="pi pi-times"
            type="text"
            severity="secondary"
            className="ml-auto white-background"
            onClick={(e: any) => {
              e.stopPropagation();
              onItemRemove(item);
            }}
          />
        )}
      </div>
    );
  };

  return (
    <>
      <StyledImageCard
        id={item.id}
        className={
          item.isPastDate && !isOnboardingPlan
            ? 'past-date item'
            : moment(item.releaseDate).isAfter(moment(), 'day')
            ? 'upcoming-course item'
            : 'item'
        }
        header={getHeaderTemplate(item)}
        footer={getFooterTemplate(item)}
        onClick={(event) => {
          menuRef.current?.toggle(event);
        }}
      />

      <Menu
        model={menuItems}
        popup
        ref={menuRef}
        style={{ width: 'auto', minWidth: '160px', marginLeft: '-10px' }}
      />

      {/* Note: Keep the preview logic as it will be added in future too */}
      {/* {isPreviewModalVisible && (
        <CourseScormPreviewModal
          courseId={item.id}
          visible={isPreviewModalVisible}
          onHide={() => setIsPreviewModalVisible(false)}
        />
      )} */}
    </>
  );
};
