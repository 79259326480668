import {
  accountADService,
  accountMigrationService,
  accountsService,
} from '@/client/accounts';
import { authService } from '@/client/auth';
import { branchesService } from '@/client/branches';
import { campaignsService } from '@/client/campaigns';
import { coursesService } from '@/client/courses';
import {
  efrontBranchService,
  efrontCourseService,
  efrontGroupService,
  efrontSyncService,
} from '@/client/efront';
import { emailTemplatesService } from '@/client/email-templates';
import filesService from '@/client/files/files.service';
import { groupsService } from '@/client/groups';
import { learnerService } from '@/client/learner-course';
import { pageTemplatesService } from '@/client/page-templates';
import { profileSettingsService } from '@/client/profile-settings';
import { rolesService } from '@/client/roles';
import { senderProfilesService } from '@/client/sender-profiles';
import {
  systemEmailSendingConfigService,
  systemEmailsTemplatesService,
  systemEmailsTypesConfigsService,
} from '@/client/system-emails';
import { currentUserService, usersService } from '@/client/users';
import axiosClientFactory from './axios-client.factory';
import { hubspotService } from './hubspot';
import { plannerService } from './planner/planner.service';
import { reportsService } from './reports';
import { resellersService } from './resellers';
import { securityCultureService } from './security-culture';
import { thumbnailsService } from './thumbnails';
import { trackingService } from './tracking';
import { ApiClientConfigType } from './types';

export const apiClientFactory = (
  config: ApiClientConfigType,
): {
  auth: ReturnType<typeof authService>;
  accounts: ReturnType<typeof accountsService>;
  resellers: ReturnType<typeof resellersService>;
  efrontGroups: ReturnType<typeof efrontGroupService>;
  efrontBranches: ReturnType<typeof efrontBranchService>;
  efrontCourses: ReturnType<typeof efrontCourseService>;
  efrontSync: ReturnType<typeof efrontSyncService>;
  adSync: ReturnType<typeof accountADService>;
  branches: ReturnType<typeof branchesService>;
  groups: ReturnType<typeof groupsService>;
  users: ReturnType<typeof usersService>;
  roles: ReturnType<typeof rolesService>;
  default: ReturnType<typeof currentUserService>;
  files: ReturnType<typeof filesService>;
  campaigns: ReturnType<typeof campaignsService>;
  senderProfiles: ReturnType<typeof senderProfilesService>;
  emailTemplates: ReturnType<typeof emailTemplatesService>;
  pageTemplates: ReturnType<typeof pageTemplatesService>;
  reports: ReturnType<typeof reportsService>;
  profileSettingsService: ReturnType<typeof profileSettingsService>;
  systemEmailsTemplatesService: ReturnType<typeof systemEmailsTemplatesService>;
  axiosRef: ReturnType<typeof axiosClientFactory>;
  systemEmailsTypesConfigsService: ReturnType<
    typeof systemEmailsTypesConfigsService
  >;
  systemEmailSendingConfigService: ReturnType<
    typeof systemEmailSendingConfigService
  >;
  accountMigrationService: ReturnType<typeof accountMigrationService>;
  learnerCourses: ReturnType<typeof learnerService>;
  courses: ReturnType<typeof coursesService>;
  thumbnails: ReturnType<typeof thumbnailsService>;
  hubspot: ReturnType<typeof hubspotService>;
  tracking: ReturnType<typeof trackingService>;
  planner: ReturnType<typeof plannerService>;
  securityCultureMails: ReturnType<typeof securityCultureService>;
} => {
  const client = axiosClientFactory(config);

  return {
    auth: authService(client),
    accounts: accountsService(client),
    resellers: resellersService(client),
    efrontGroups: efrontGroupService(client),
    efrontBranches: efrontBranchService(client),
    efrontCourses: efrontCourseService(client),
    efrontSync: efrontSyncService(client),
    adSync: accountADService(client),
    branches: branchesService(client),
    groups: groupsService(client),
    users: usersService(client),
    roles: rolesService(client),
    default: currentUserService(client),
    files: filesService(client),
    campaigns: campaignsService(client),
    senderProfiles: senderProfilesService(client),
    emailTemplates: emailTemplatesService(client),
    pageTemplates: pageTemplatesService(client),
    reports: reportsService(client),
    profileSettingsService: profileSettingsService(client),
    systemEmailsTemplatesService: systemEmailsTemplatesService(client),
    axiosRef: client,
    systemEmailsTypesConfigsService: systemEmailsTypesConfigsService(client),
    systemEmailSendingConfigService: systemEmailSendingConfigService(client),
    accountMigrationService: accountMigrationService(client),
    courses: coursesService(client),
    learnerCourses: learnerService(client),
    thumbnails: thumbnailsService(client),
    hubspot: hubspotService(client),
    tracking: trackingService(client),
    planner: plannerService(client),
    securityCultureMails: securityCultureService(client),
  };
};
