import React from 'react';
import styled from 'styled-components';

const StyledThumbnail = styled.div`
  flex-shrink: 0;
  width: 76px;
  height: 66px;
  overflow: hidden;
  border-radius: 4px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

type DatatableThumbnailProps = {
  url: string;
  alt?: string;
  className?: string;
};

const DatatableThumbnail: React.FC<DatatableThumbnailProps> = ({
  url,
  alt = '',
  ...rest
}) => {
  return (
    <StyledThumbnail {...rest}>
      <img src={url} alt={alt} />
    </StyledThumbnail>
  );
};

export default DatatableThumbnail;
