import { LanguagesEnum } from '@/api/enums';
import { equal, handleAxiosError } from '@/api/helpers';
import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import { client } from '@/client';
import {
  Account,
  AccountActionsEnum,
  AccountCourseDelivery,
  AccountFormState,
  AccountTypeEnum,
} from '@/client/accounts';
import { Actions, Subjects, SystemRoles } from '@/client/users';
import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import { AppContext } from '@/common/context/AppContext';
import { TranslationFunctionType } from '@/common/types';
import { AccountForm } from '@/components/accounts/forms';
import { useAccount, useSaveAccount, useUpdateAccounts } from '@/hooks/query';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { usePermission } from '@/hooks/usePermission';
import { useToast } from '@/hooks/useToast';
import { AccountTabs } from '@/pages/accounts/AccountTabs';
import {
  selectCurrentAccount,
  setCurrentAccount,
} from '@/store/features/account';
import { selectCurrentUser } from '@/store/features/users';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import { FlexContainer } from '@/ui/styled-ui';
import { queryStateConverter } from '@/utils/helpers';
import { AxiosError } from 'axios';
import { omit } from 'lodash';
import { MenuItem } from 'primereact/menuitem';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const getPathItems = (
  account: Account | undefined,
  currentAccount: Account | null,
  action: string | undefined,
  t: TranslationFunctionType,
): MenuItem[] => {
  const pathItems: MenuItem[] = [];

  if (currentAccount && currentAccount.isSystem) {
    pathItems.push(
      {
        label: currentAccount?.name,
        url: RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](currentAccount?.id),
        template: AppBreadCrumbTemplate,
      },
      {
        label: t('accounts'),
        url: RedirectPaths[RedirectPathsEnum.ACCOUNTS](),
        template: AppBreadCrumbTemplate,
      },
    );

    if (account) {
      if (action !== AccountActionsEnum.CREATE) {
        pathItems.push({
          label: account?.name,
          url: RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](account?.id),
          template: AppBreadCrumbTemplate,
        });
      }
    }

    if (action === AccountActionsEnum.CREATE) {
      pathItems.push({
        label: t('account.createNew'),
        url: RedirectPaths[RedirectPathsEnum.CREATE_ACCOUNT](),
        template: AppBreadCrumbTemplate,
      });
    }
  } else if (currentAccount && !currentAccount.isSystem) {
    pathItems.push({
      label: currentAccount?.name,
      url: RedirectPaths[RedirectPathsEnum.ACCOUNT](currentAccount?.id),
      template: AppBreadCrumbTemplate,
    });
  }

  return pathItems;
};

export const CreateUpdateAccountPage = () => {
  const { t } = useTranslation();
  const { triggerAccountsLengthChange } = useContext(AppContext);
  const navigate = useNavigate();
  const { id } = useParams();
  const { pathname } = useLocation();
  const toast = useToast();
  const { cannot } = usePermission();
  const dispatch = useAppDispatch();

  const { account, isLoading, refetch } = useAccount({
    accountId: id,
  });

  const currentAccount = useAppSelector(selectCurrentAccount);
  const currentUser = useAppSelector(selectCurrentUser);

  const addAccount = useSaveAccount();
  const updateAccount = useUpdateAccounts();

  const [action, setAction] = useState<AccountActionsEnum>();

  const [initialValues, setInitialValues] = useState<AccountFormState>({
    name: '',
    active: false,
    subdomain: '',
    isTrackingEnabled: false,
    enableCoursePlanner: false,
    enableMaterialsUpload: false,
    autoCourseAvailability: false,
    autoCourseEnrollment: false,
    isPhishingOnly: false,
    showCampaignOpenEvents: false,
    freeTrialCheck: false,
    freeTrialEndsAt: null,
    reseller: null,
    mainAccountOfReseller: null,
    defaultLanguage: LanguagesEnum.EN,
    type: false,
    hubspotId: '',
    hubspotDomains: [],
    courseDelivery: AccountCourseDelivery.CP_APP,
    courseDueDaysCheck: true,
    courseDueDays: 30,
  });

  useEffect(() => {
    if (pathname.includes(AccountActionsEnum.CREATE)) {
      setAction(AccountActionsEnum.CREATE);
      return;
    } else {
      setAction(AccountActionsEnum.UPDATE);
    }

    if (!account && !id && currentAccount) {
      setInitialValues({
        id: currentAccount?.id,
        name: currentAccount?.name,
        active: currentAccount?.active,
        subdomain: currentAccount.subdomain,
        isTrackingEnabled: currentAccount?.isTrackingEnabled,
        enableCoursePlanner: currentAccount?.meta?.enableCoursePlanner,
        enableMaterialsUpload: currentAccount?.meta?.enableMaterialsUpload,
        autoCourseAvailability: currentAccount?.meta?.autoCourseAvailability,
        autoCourseEnrollment: currentAccount?.meta?.autoCourseEnrollment,
        isPhishingOnly: currentAccount?.isPhishingOnly,
        showCampaignOpenEvents: currentAccount?.meta?.showCampaignOpenEvents,
        freeTrialCheck: !!currentAccount?.freeTrialEndsAt,
        freeTrialEndsAt: currentAccount?.freeTrialEndsAt,
        reseller: currentAccount?.reseller,
        mainAccountOfReseller: currentAccount?.mainAccountOfReseller,
        defaultLanguage: currentAccount?.defaultLanguage,
        type: currentAccount?.type === AccountTypeEnum.DEMO,
        hubspotId: currentAccount?.hubspot?.id ?? '',
        hubspotDomains: [
          currentAccount?.hubspot?.primaryDomain || '',
          ...(currentAccount?.hubspot?.additionalDomains || []),
        ].filter(Boolean),
        courseDelivery: currentAccount?.courseDelivery,
        courseDueDaysCheck: !!currentAccount?.courseDueDays,
        courseDueDays: currentAccount?.courseDueDays,
      });
    }

    if (account) {
      setInitialValues({
        id: account?.id,
        name: account?.name,
        active: account?.active,
        subdomain: account.subdomain,
        isTrackingEnabled: account?.isTrackingEnabled,
        enableCoursePlanner: account?.meta?.enableCoursePlanner,
        enableMaterialsUpload: account?.meta?.enableMaterialsUpload,
        autoCourseAvailability: account?.meta?.autoCourseAvailability,
        autoCourseEnrollment: account?.meta?.autoCourseEnrollment,
        isPhishingOnly: account?.isPhishingOnly,
        showCampaignOpenEvents: account?.meta?.showCampaignOpenEvents,
        freeTrialCheck: !!account?.freeTrialEndsAt,
        freeTrialEndsAt: account?.freeTrialEndsAt,
        reseller: account?.reseller,
        mainAccountOfReseller: account?.mainAccountOfReseller,
        defaultLanguage: account?.defaultLanguage,
        type: account?.type === AccountTypeEnum.DEMO,
        hubspotId: account?.hubspot?.id ?? '',
        hubspotDomains: [
          account?.hubspot?.primaryDomain || '',
          ...(account?.hubspot?.additionalDomains || []),
        ].filter(Boolean),
        courseDelivery: account?.courseDelivery,
        courseDueDaysCheck: !!account?.courseDueDays,
        courseDueDays: account?.courseDueDays,
      });
    }
  }, [account, currentAccount]);

  const handleCheckIfSubdomainExists = async (subdomain?: string) => {
    if (!subdomain) return false;

    const accounts = await client.accounts.getAccounts({
      filters: [equal('subdomain', subdomain)],
    });

    return !!accounts.count;
  };

  const handleAccountAction = async (data: AccountFormState, id?: string) => {
    let response: Account;

    const systemAccountPayload = {
      name: data.name,
      subdomain: data.subdomain,
      active: data.active,
      autoCourseAvailability: data.autoCourseAvailability,
      autoCourseEnrollment: data.autoCourseEnrollment,
      isSSO: data.isSSO,
      isPhishingOnly: data.isPhishingOnly,
      showCampaignOpenEvents: data?.showCampaignOpenEvents,
      freeTrialEndsAt: data.freeTrialEndsAt,
      reseller: data.reseller ? data.reseller.id : null,
      isTrackingEnabled: data.isTrackingEnabled,
      enableCoursePlanner: data?.enableCoursePlanner,
      enableMaterialsUpload: data?.enableMaterialsUpload,
      defaultLanguage: data.defaultLanguage ? data.defaultLanguage : null,
      type: data.type ? AccountTypeEnum.DEMO : AccountTypeEnum.REAL,
      hubspotId: !data.type ? data.hubspotId : undefined,
      hubspotPrimaryDomain: !data.type ? data?.hubspotDomains[0] : undefined,
      hubspotAdditionalDomains: !data.type
        ? data?.hubspotDomains?.slice(1)
        : undefined,
      courseDelivery: data.courseDelivery,
      courseDueDays: data.courseDueDays,
    };

    const customerAccountPayload = {
      defaultLanguage: data.defaultLanguage ? data.defaultLanguage : null,
      courseDueDays: data.courseDueDays,
    };

    if (action === AccountActionsEnum.CREATE) {
      response = await addAccount.create({
        ...systemAccountPayload,
        eFrontSync: false,
        isMigratedOutsideOfEFront: true,
      });
      triggerAccountsLengthChange && triggerAccountsLengthChange();
    } else {
      if (!id) return;
      response = await updateAccount.update({
        accountId: id,
        updates: omit(
          currentAccount?.isSystem
            ? systemAccountPayload
            : customerAccountPayload,
          ['account', account?.subdomain ? 'subdomain' : ''],
        ),
      });
    }

    if (action === AccountActionsEnum.CREATE) {
      navigate(RedirectPaths[RedirectPathsEnum.ACCOUNTS]());
    } else {
      refetch();
    }

    return response;
  };

  const handleSubmit = async (data: AccountFormState) => {
    try {
      if (
        (!initialValues?.subdomain &&
          (await handleCheckIfSubdomainExists(data.subdomain))) ||
        data.subdomain === 'app'
      ) {
        toast?.error(t('toast.error'), t('account.subdomain.exits'));

        return;
      }

      const response =
        account || currentAccount
          ? await handleAccountAction(data, account?.id ?? currentAccount?.id)
          : await handleAccountAction(data);

      if (
        action === AccountActionsEnum.UPDATE &&
        response &&
        response?.id === currentAccount?.id
      ) {
        dispatch(setCurrentAccount({ ...currentAccount, ...response }));
      }

      toast?.success(
        t('toast.success'),
        action === AccountActionsEnum.CREATE
          ? t('account.created')
          : t('account.updated'),
      );
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  return (
    <>
      {isLoading ? (
        <FlexContainer direction="column" className="mt-5">
          <ProgressSpinner />
        </FlexContainer>
      ) : (
        <>
          <AppBreadCrumb
            model={getPathItems(account, currentAccount, action, t)}
          />
          <h1 className="mt-4 mb-4">{account?.name ?? currentAccount?.name}</h1>
          {account && <AccountTabs account={account} />}
          {currentAccount &&
            !account &&
            action !== AccountActionsEnum.CREATE && (
              <AccountTabs account={currentAccount} />
            )}

          <FlexContainer justify="flex-start">
            <AccountForm
              isCreate={!initialValues?.id}
              accountId={account?.id}
              initialValues={initialValues}
              state={queryStateConverter(account ? updateAccount : addAccount)}
              disabled={
                cannot(Actions.UPDATE, Subjects.ACCOUNTS) ||
                !currentAccount?.isSystem
              }
              locked={
                account?.isSystem &&
                currentUser?.role.code !== SystemRoles.DEVELOPER
              }
              onSubmit={handleSubmit}
            />
          </FlexContainer>
        </>
      )}
    </>
  );
};
