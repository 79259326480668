import { FiltersType, ListingRequest } from '@/api/types';
import { client } from '@/client';
import { Branch } from '@/client/branches';
import { MessageResponseModel } from '@/client/models';
import {
  DeleteUserRequest,
  ListRoleResponse,
  ListUserCoursesResponse,
  ListUsersResponse,
  SaveUserRequest,
  UpdateUserRequest,
  User,
} from '@/client/users';
import {
  DeleteUserMutation,
  GenericForbiddenApiError,
  SaveUserMutation,
} from '@/hooks/query';
import { partialRequests } from '@/utils/helpers';
import {
  useMutation,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';

const queryKey = 'users';
const singleQueryKey = 'user';

export const useUsers = (
  params: UseQueryOptions & {
    take?: number;
    skip?: number;
    sort?: string[];
    filters?: FiltersType;
    withBranches?: boolean;
    withGroups?: number;
  },
): UseQueryResult<ListUsersResponse> & {
  users: ListUsersResponse | undefined;
} => {
  const {
    take = 5,
    skip = 0,
    sort,
    filters,
    retry = false,
    retryDelay = 500,
    staleTime = 0,
    cacheTime = 0,
    ...restOptions
  } = params;

  const { data, ...rest } = useQuery(
    [queryKey, take, skip, JSON.stringify(sort), JSON.stringify(filters)],
    () => client.users.getUsers(params),
    {
      retry,
      retryDelay,
      staleTime,
      cacheTime,
      ...(restOptions as any),
    },
  );

  return {
    users: data,
    ...(rest as UseQueryResult<ListUsersResponse>),
  };
};

export const useAccountUsers = (
  params: UseQueryOptions & {
    accountId?: string;
    take?: number;
    skip?: number;
    sort?: string[];
    filters?: FiltersType;
    withBranches?: boolean;
    withGroups?: number;
  },
): UseQueryResult<ListUsersResponse> & {
  users: ListUsersResponse | undefined;
} => {
  const {
    accountId,
    take = 5,
    skip = 0,
    sort,
    filters,
    retry = false,
    retryDelay = 500,
    staleTime = 0,
    cacheTime = 0,
    ...restOptions
  } = params;

  const { data, ...rest } = useQuery(
    [queryKey, take, skip, JSON.stringify(sort), JSON.stringify(filters)],
    () => client.users.getAccountUsers(params, accountId),
    {
      retry,
      retryDelay,
      staleTime,
      cacheTime,
      ...(restOptions as any),
    },
  );

  return {
    users: data,
    ...(rest as UseQueryResult<ListUsersResponse>),
  };
};

export const useUsersPartialRequest = (
  params: UseQueryOptions & {
    sort?: string[];
    filters?: FiltersType;
    withBranches?: boolean;
    withGroups?: number;
  },
): UseQueryResult<User[]> & {
  users: User[] | undefined;
} => {
  const {
    sort,
    filters,
    retry = false,
    retryDelay = 500,
    staleTime = 0,
    cacheTime = 0,
    ...restOptions
  } = params;
  const usersRequest = async (params: ListingRequest) =>
    await client.users.getUsers(params);
  const { data, ...rest } = useQuery(
    [`${queryKey}-partial`, JSON.stringify(sort), JSON.stringify(filters)],
    () => partialRequests(filters || [], usersRequest, params),
    {
      retry,
      retryDelay,
      staleTime,
      cacheTime,
      ...(restOptions as any),
    },
  );

  return {
    users: data,
    ...(rest as UseQueryResult<User[]>),
  };
};

export const useAccountUsersPartialRequest = (
  params: UseQueryOptions & {
    accountId?: string;
    sort?: string[];
    filters?: FiltersType;
    withBranches?: boolean;
    withGroups?: number;
  },
): UseQueryResult<User[]> & {
  users: User[] | undefined;
} => {
  const {
    accountId,
    sort,
    filters,
    retry = false,
    retryDelay = 500,
    staleTime = 0,
    cacheTime = 0,
    ...restOptions
  } = params;
  const usersRequest = async (params: ListingRequest) =>
    await client.users.getAccountUsers(params, accountId);
  const { data, ...rest } = useQuery(
    [`${queryKey}-partial`, JSON.stringify(sort), JSON.stringify(filters)],
    () => partialRequests(filters || [], usersRequest, params),
    {
      retry,
      retryDelay,
      staleTime,
      cacheTime,
      ...(restOptions as any),
    },
  );

  return {
    users: data,
    ...(rest as UseQueryResult<User[]>),
  };
};

export const useUser = (
  params: UseQueryOptions & {
    userId?: string;
    accountId?: string;
  },
): UseQueryResult<User> & {
  user: User | undefined;
} => {
  const {
    accountId,
    userId,
    retry = false,
    retryDelay = 500,
    staleTime = 0,
    cacheTime = 0,
    ...restOptions
  } = params;

  const { data, ...rest } = useQuery(
    [singleQueryKey, userId],
    () => (userId ? client.users.getUser(userId, accountId) : undefined),
    {
      retry,
      retryDelay,
      staleTime,
      cacheTime,
      ...(restOptions as UseQueryResult<Branch>),
    },
  );

  return {
    user: data,
    ...(rest as UseQueryResult<User>),
  };
};

export const useSaveUser = (): {
  create: (payload: SaveUserRequest) => Promise<User>;
} & SaveUserMutation => {
  const { mutateAsync, ...rest } = useMutation<
    User,
    GenericForbiddenApiError,
    SaveUserRequest
  >('addUser', (payload: SaveUserRequest) => client.users.saveUser(payload));

  return { create: mutateAsync, ...(rest as any) };
};

export const useUpdateUser = (): {
  update: (payload: UpdateUserRequest) => Promise<User>;
} & SaveUserMutation => {
  const { mutateAsync, ...rest } = useMutation<
    User,
    GenericForbiddenApiError,
    UpdateUserRequest
  >('updateUser', (payload: UpdateUserRequest) =>
    client.users.updateUser(payload),
  );

  return { update: mutateAsync, ...(rest as any) };
};

export const useDeleteUser = (): {
  delete: (payload: DeleteUserRequest) => Promise<MessageResponseModel>;
} & DeleteUserMutation => {
  const { mutateAsync, ...rest } = useMutation<
    MessageResponseModel,
    GenericForbiddenApiError,
    DeleteUserRequest
  >('deleteUser', (payload: DeleteUserRequest) =>
    client.users.deleteUser(payload.userId, payload.accountId),
  );

  return { delete: mutateAsync, ...(rest as any) };
};

export const useRoles = (
  params: UseQueryOptions & {
    take?: number;
    skip?: number;
    sort?: string[];
    filters?: FiltersType;
    withBranches?: boolean;
  },
): UseQueryResult<ListRoleResponse> & {
  roles: ListRoleResponse | undefined;
} => {
  const {
    take = 5,
    skip = 0,
    sort,
    filters,
    retry = false,
    retryDelay = 500,
    staleTime = 0,
    cacheTime = 0,
    ...restOptions
  } = params;

  const { data, ...rest } = useQuery(
    [queryKey, take, skip, JSON.stringify(sort), JSON.stringify(filters)],
    () => client.roles.getRoles(params),
    {
      retry,
      retryDelay,
      staleTime,
      cacheTime,
      ...(restOptions as any),
    },
  );

  return {
    roles: data,
    ...(rest as UseQueryResult<ListRoleResponse>),
  };
};

export const useUserCourses = (
  params: UseQueryOptions & {
    accountId?: string;
    userId?: string;
    take?: number;
    skip?: number;
    sort?: string[];
    filters?: FiltersType;
  },
): UseQueryResult<ListUserCoursesResponse> & {
  userCourses: ListUserCoursesResponse | undefined;
} => {
  const {
    accountId,
    userId,
    take = 5,
    skip = 0,
    sort,
    filters,
    retry = false,
    retryDelay = 500,
    staleTime = 0,
    cacheTime = 0,
    ...restOptions
  } = params;

  const { data, ...rest } = useQuery(
    [
      `${queryKey}UserCourses`,
      take,
      skip,
      JSON.stringify(sort),
      JSON.stringify(filters),
    ],
    () => client.users.getUserCourses(params, accountId, userId),
    {
      retry,
      retryDelay,
      staleTime,
      cacheTime,
      ...(restOptions as any),
    },
  );

  return {
    userCourses: data,
    ...(rest as UseQueryResult<ListUserCoursesResponse>),
  };
};
