import { languageDisplayLabel, LanguagesEnum } from '@/api/enums';
import { Course } from '@/client/courses';
import { DialogContext } from '@/common/context';
import { sortAlphabetically } from '@/utils/helpers';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import { SelectItemOptionsType } from 'primereact/selectitem';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StyledDropdown = styled(Dropdown)`
  border-radius: var(--default-border-radius);
  border: 1px solid var(--purple-dark);
  background: var(--white-main);

  .p-dropdown-label.p-placeholder,
  .p-dropdown-label.p-inputtext {
    background: var(--white-main);
  }

  .p-dropdown-label.p-inputtext {
    padding-right: 0px;
  }

  &.player {
    width: 100%;
  }
`;

type CourseLanguageChangerProps = {
  course: Course;
  onChange: (value: LanguagesEnum) => void;
  language?: LanguagesEnum;
  shouldShowWarning?: boolean;
  className?: string;
};

export const CourseLanguageChanger: React.FC<CourseLanguageChangerProps> = ({
  course,
  onChange,
  language,
  shouldShowWarning,
  className,
}) => {
  const { t } = useTranslation();
  const { setDialogData } = useContext(DialogContext);
  const [sortedCourseFiles, setSortedCourseFiles] =
    useState<SelectItemOptionsType>([]);

  useEffect(() => {
    setSortedCourseFiles(
      course.courseFiles
        .sort((a, b) =>
          sortAlphabetically(
            languageDisplayLabel[a.language],
            languageDisplayLabel[b.language],
          ),
        )
        .map(({ language }) => ({
          id: language,
          label: languageDisplayLabel[language],
        })),
    );
  }, [course]);

  const onChangeHandler = (e: DropdownChangeEvent) => {
    if (shouldShowWarning) {
      setDialogData({
        type: 'confirmation',
        show: true,
        header: t('dialog.course.language'),
        message: t('dialog.course.language.confirm'),
        onAccept: () => onChange(e.value),
      });
    } else {
      onChange(e.value);
    }
  };

  return (
    <StyledDropdown
      id="course-language"
      placeholder={t('generic.select')}
      value={language}
      optionValue="id"
      optionLabel="label"
      onChange={onChangeHandler}
      options={sortedCourseFiles}
      className={className}
    />
  );
};
