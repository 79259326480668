import { processListingParams } from '@/api/helpers';
import { getExceptionFromAxiosError } from '@/client/helpers';
import i18n from '@/i18n';
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { MessageResponseModel } from '../models';
import {
  AddCoursePlannersRequest,
  CoursePlanner,
  DeletePlannerRequest,
  ListCoursePlannerRequest,
  ListCoursePlannerResponse,
  SavePlannerRequest,
} from './types';

export const plannerService = (client: AxiosInstance) => {
  const PLANNER_BASE_URL = '/account';
  const COURSE_PLANNERS_URL = 'course-planners';

  const createPlanner = async (
    accountId: string,
    request: SavePlannerRequest,
  ): Promise<CoursePlanner> => {
    try {
      const result = await client.post(
        `${PLANNER_BASE_URL}/${accountId}/course-planner`,
        request,
        {
          withCredentials: true,
          headers: {
            'x-accept-language': i18n.language,
          },
        },
      );

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const updatePlanner = async (
    accountId: string,
    request: SavePlannerRequest,
  ): Promise<CoursePlanner> => {
    try {
      const result = await client.patch(
        `${PLANNER_BASE_URL}/${accountId}/course-planner/${request.id}`,
        request,
        {
          withCredentials: true,
          headers: {
            'x-accept-language': i18n.language,
          },
        },
      );

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const getPlanners = async (
    params: ListCoursePlannerRequest,
    accountId?: string,
  ): Promise<ListCoursePlannerResponse> => {
    try {
      const result = await client.get<
        ListCoursePlannerResponse,
        AxiosResponse<ListCoursePlannerResponse>
      >(`${PLANNER_BASE_URL}/${accountId}/course-planner`, {
        withCredentials: true,
        params: processListingParams(params),
        headers: {
          'x-accept-language': i18n.language,
        },
      });

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const deletePlanner = async (
    request: DeletePlannerRequest,
  ): Promise<MessageResponseModel> => {
    try {
      const result = await client.delete(
        `${PLANNER_BASE_URL}/${request.accountId}/course-planner/${request.planId}`,
        {
          withCredentials: true,
        },
      );

      return new MessageResponseModel(result.data);
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const addCoursePlanners = async (
    request: AddCoursePlannersRequest,
  ): Promise<MessageResponseModel> => {
    try {
      const result = await client.post(
        `/${COURSE_PLANNERS_URL}/courses`,
        request,
        {
          withCredentials: true,
        },
      );

      return result.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const coursePlannersNotifyUrl = () =>
    `${client.defaults.baseURL}${COURSE_PLANNERS_URL}/notify`;

  return {
    getPlanners,
    createPlanner,
    updatePlanner,
    deletePlanner,
    addCoursePlanners,
    coursePlannersNotifyUrl,
  };
};
