import { LanguagesEnum } from '@/api/enums';
import { ListingRequest, ListingResponse } from '@/api/types';
import { Course } from '@/client/courses';
import { ScormVersion } from '@/scorm/types';

export enum ScormCMICompletionStatusEnum {
  BROWSED = 'browsed', // Only for SCORM 1.2
  NOT_ATTEMPTED = 'not attempted',
  INCOMPLETE = 'incomplete',
  COMPLETED = 'completed',
  PASSED = 'passed', // Only for SCORM 1.2
  FAILED = 'failed', // Only for SCORM 1.2
  UNKNOWN = 'unknown', // Only for SCORM 2004
}

export type LearnerCourseDispatchResponse = {
  url: string;
  version: ScormVersion;
  cookies: {
    'CloudFront-Key-Pair-Id': string;
    'CloudFront-Signature': string;
    'CloudFront-Expires'?: number;
    'CloudFront-Policy'?: string;
  };
};

export type UpdateCourseAttemptRequest = {
  courseId: string;
  attemptId: string;
  payload?: Record<string, any>;
  language?: LanguagesEnum;
};

export type ListLearnerCoursesRequest = ListingRequest;
export type ListLearnerCoursesResponse = ListingResponse<Course>;
